<template>
  <div class="w-full pb-[20px]">
    <div class="arrow-container">
      <p class="text-center max-w-xs mx-auto">
        We analyze popular carriers in your area
      </p>
    </div>
    <div
      class="bg-blue-600 text-white p-4 flex gap-4 justify-around z-2 pb-8 py-6 items-center"
    >
      <div class="carrier">Humana</div>
      <div class="carrier">UnitedHealthcare®</div>
      <div class="carrier">Anthem Blue Cross</div>
    </div>
  </div>
</template>

<style scoped>
.carrier {
  @apply text-center px-2 text-sm;
}
.arrow-container {
  @apply bg-blue-500 text-white p-4 z-4 bg-gradient-to-b from-white via-blue-100 to-blue-100 flex flex-col items-center text-black;
}
.arrow-container::after {
  content: ' ';
  @apply z-3;
  margin-top: 38px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid;
  border-top-color: rgb(219 234 254);
}
</style>
