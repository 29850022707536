<script setup lang="ts">
const open = ref(false)
const content = ref<any>()
const type = ref<'ad' | 'subscription'>('ad')

const onClickFirst = () => {
  type.value = 'ad'
  content.value = {
    title: `Exclusive Benefits Just For You!`,
    description: `
    <p>Need money for groceries?</p>
    <p>Call us to discover all the perks awaiting for you</p>`,
    cta: {
      label: 'Click here to know more',
      url: 'https://www.google.com',
    },
    closeLabel: 'No, thanks',
  }
  open.value = true
}

const onClickSecond = () => {
  type.value = 'subscription'
  content.value = {
    title: `Do you want to know if you're elegible for a new Medicare plan?`,
    description: `Call the ${brand.name}`,
    cta: {
      label: 'Click here to know more',
      url: 'https://www.google.com',
    },
    closeLabel: 'No, thanks',
  }
  open.value = true
}
</script>

<template>
  <Layout class="bg-[#273238] flex flex-col min-h-[100vh] gap-4" footer="2024">
    <Button @click="onClickFirst">Open Ad modal</Button>
    <Button @click="onClickSecond">Open Subscription modal</Button>
    <GlobalModal :open="open">
      <GlobalModalAd
        v-if="type === 'ad'"
        :content="content"
        :close-modal="() => (open = false)"
      />
      <GlobalModalSubscription
        v-if="type === 'subscription'"
        :content="content"
        :close-modal="() => (open = false)"
      />
    </GlobalModal>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer"],
    "robots": "noindex"
  }
}
</route>
