<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <div class="text-xl sm:text-3xl font-bold text-center mt-12">
    Good news, I was able to find plans in your area with benefits that you're
    looking for!
  </div>

  <img
    src="../../assets/grocery-2024.jpeg"
    width="1100"
    height="576"
    class="w-[90%] sm:w-[400px] mx-auto"
  />

  <div class="text-3xl sm:text-4xl text-center">
    Click below to learn more with licensed insurance agent
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Act quickly: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
