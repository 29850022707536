<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state) {
    return {
      code: state.code,
      name: state.name,
      medicaid: state.medicaid,
    }
  }
})
</script>
<template>
  <div class="py-8 space-y-6 container">
    <div class="text-center text-lg font-semibold mb-2">
      Are you
      <span class="text-blue-600">
        receiving benefits from {{ state?.name }} Medicaid</span
      ><span
        v-if="state?.medicaid && state.medicaid !== `${state?.name} Medicaid`"
        >, also known as {{ state.medicaid }} </span
      >?
    </div>
    <div class="text-center text-base text-gray-600 mb-2">
      Medicaid provides coverage for some limited-income people.
    </div>

    <div class="flex flex-col gap-4">
      <Button
        wide
        size="sm"
        type="submit"
        variant="undefined"
        class="bg-blue-600 text-white py-2 !rounded-full shadow-md"
        @click="haveMedicaid = true"
      >
        Yes
      </Button>
      <Button
        wide
        size="sm"
        type="submit"
        variant="undefined"
        class="border border-blue-600 text-blue-600 py-2 !rounded-full shadow-md"
        @click="haveMedicaid = false"
      >
        No
      </Button>
      <Button
        wide
        size="sm"
        type="submit"
        variant="gray"
        class="col-span-2"
        @click="haveMedicaid = null"
      >
        Skip
      </Button>
    </div>
  </div>
</template>
