<script setup lang="ts">
useHead({
  title: `Medicare Premium Reduction Simple 2096 - ${brand.name}`,
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template #banner>
      <Banner> New to Medicare? Moving? </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-3 py-6">
      <div class="text-2xl sm:text-xl text-center font-semibold">
        2025 Medicare Advantage Plans With Part B Premium Reduction
      </div>

      <img
        src="../../assets/card-medicare-b.png"
        alt="Medicare Card with $174 green stamp"
        width="1000"
        height="675"
        class="w-64 md:w-80 mx-auto"
      />

      <div
        class="text-3xl sm:text-4xl font-semibold text-red text-center font-bold"
      >
        $2096/yr
      </div>

      <div class="text-lg sm:text-xl text-center font-medium">
        ^ 2025 Medicare Part B Premium ^
      </div>

      <div class="text-xl sm:text-2xl text-center font-medium">
        SEE IF YOU'RE ELIGIBLE FOR A PLAN WITH A PART B PREMIUM REDUCTION
        <strong>(LOWER MONTHLY PREMIUMS)</strong>
        WITH SOME PLANS FROM CARRIERS LIKE HUMANA AND UNITEDHEALTHCARE®
      </div>

      <div class="text-center text-lg">
        Not all plans offer all of these benefits. Benefits may vary by carrier
        and location. Limitations and exclusions may apply.
      </div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <UseForm v-slot="{ values }">
          <Form
            id="medicarePremiumReductionSimple2096"
            class="!max-w-3xl p-4"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'zipcode' },
              { id: 'medicareab' },
              { id: 'medicaid' },
              { id: 'call', completed: true },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0924240_M",
      "m10": "MULTI-PLAN_HLWEB0624185_M"
    }
  }
}
</route>
