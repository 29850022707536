<script setup lang="ts">
useHead({
  title: `See if you qualify - ${brand.name}`,
})

analytics.page({
  name: 'LP Discover Benefits 1.0',
  category: 'LP',
  product: 'medicare',
})

const showFooterPhone = ref(true)
</script>

<template>
  <Layout header="ping" class="container flex flex-col">
    <div class="flex-1 py-16">
      <div class="max-w-xl mx-auto space-y-6">
        <div class="text-2xl sm:text-3xl">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </div>

        <div
          class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
        >
          <div class="flex items-center gap-4">
            <Icon i-heroicons-outline:check class="text-blue" />
            <div class="flex-1 text-lg"> Medicare Advantage Plans </div>
          </div>
          <div class="flex items-center gap-4">
            <Icon i-heroicons-outline:check class="text-blue" />
            <div class="flex-1 text-lg"> Medicare Supplement Plans </div>
          </div>
          <div class="flex items-center gap-4">
            <Icon i-heroicons-outline:check class="text-blue" />
            <div class="flex-1 text-lg"> Medicare Part D Plans </div>
          </div>
        </div>

        <Button
          wide
          size="sm"
          :to="
            query.flow === 'helpline'
              ? '/helpline'
              : query.flow === 'additional-benefits-buyback-2024'
              ? '/additional-benefits-buyback-2024'
              : query.flow === 'additional-benefits-grocery-2024'
              ? '/additional-benefits-grocery-2024'
              : '/healthy-foods'
          "
        >
          <span> Get Started </span>
          <Icon i-heroicons-solid:arrow-right class="ml-2" />
        </Button>
      </div>

      <FooterPhone2 v-if="showFooterPhone" @close="showFooterPhone = false" />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["med10", "bh"],
    "robots": "noindex"
  }
}
</route>
