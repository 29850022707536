<script setup lang="ts">
useHead({
  title: `Call ${brand.name} to learn more`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout hideHeader footer="2024" class="container">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Some Americans with Medicare A&B are getting
        <span class="text-red">
          extra benefits like dental, vision, and money back*
        </span>
        on their social security with new Part C Plans
      </div>

      <img
        src="../../assets/call-4-extra-benefits.png"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div
        class="py-6 px-4 -mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:px-6 sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="call4ExtraBenefitsSep"
            class="!max-w-3xl"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'us' },
              { id: 'medicare' },
              { id: 'newToMedicare', if: values?.haveMedicare === false },
              { id: 'medicaid' },
              { id: 'chronic', if: values?.haveMedicaid === false },
              { id: 'moved', if: values?.haveChronic === false },
              { id: 'interested' },
              { id: 'loading' },
              { id: 'call' },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["dental", "vision", "giveback"],
    "brands": ["m10", "bh"],
    "smid": "MULTI-PLAN_EHWEB092206_V2_M",
    "robots": "noindex"
  }
}
</route>
