<script setup lang="ts">
useHead({
  title: `Dental Allowance 24 - ${brand.name}`,
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template #banner>
      <Banner> New to Medicare? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <img
        src="../../assets/dental-low-copay-1.png"
        alt="Happy senior lady in a dental chair"
        width="539"
        height="464"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div class="text-center">
        <div class="text-xl sm:text-2xl font-bold text-red">
          2025 Medicare Advantage
        </div>
        <div class="text-xl sm:text-2xl text-red">
          DENTAL ALLOWANCE BENEFIT
        </div>
      </div>

      <div class="text-gray-500 text-center">
        Not all plans offer all of these benefits. Benefits may vary by carrier
        and location. Limitations and exclusions may apply.
      </div>

      <div class="text-xl text-red text-center font-medium">
        Get dental care benefits.
      </div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="dentalAllowance24"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'zipcode' },
            { id: 'medicaid' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>

      <div class="text-xl text-blue text-center">
        Shop plans from carriers like <br />
        <span class="font-medium"> HUMANA, UNITEDHEALTHCARE® </span>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["dental"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624186_M",
      "m10": "MULTI-PLAN_HLWEB1024269_M"
    }
  }
}
</route>
