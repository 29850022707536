<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>

const { value: wantBenefit } = useField('wantBenefit')

const minOtcLtd = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd
})

const minOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc
})
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center">
    <template v-if="variant === 'default'">
      Do you want
      {{ minOtcLtd ? `$${minOtcLtd}/yr or more` : 'an allowance' }} to use
      towards common over-the-counter items or groceries?
    </template>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantBenefit = null"
    >
      Prefer not to say
    </Button>
  </div>
</template>
