<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const props = defineProps<{
  plan: PlanRowView
  isExpandable: boolean
}>()

const currentBenefitOpen = ref<number | null>(null)

const toggleCurrentBenefitOpen = (index: number) => {
  currentBenefitOpen.value = index === currentBenefitOpen.value ? null : index
}
</script>

<template>
  <div v-if="plan" class="flex flex-col w-full shadow-md bg-white rounded-xl">
    <div
      :class="['header']"
      :style="{ backgroundColor: plan.carrierGroupColor }"
    >
      <div class="flex gap-2 justify-between">
        <h2 class="text-xl text-white">
          {{ plan.carrier }} <br />
          <span class="text-sm font-normal font-sans">{{
            plan.plan_name
          }}</span>
        </h2>
      </div>
    </div>
    <div class="body">
      <div
        class="flex gap-2 justify-between font-sans text-sm md:text-base text-white"
      >
        <span class="flex gap-2 items-center">
          Plan year 2024
          <Icon i-heroicons-outline:information-circle class="bg-white w-4" />
        </span>
        <div class="enrollments">
          <span> {{ plan.enrollments }} members </span>
          <span
            :class="[
              plan.growth >= 0 ? 'text-green-600' : 'text-orange-600',
              'inline-block ml-2 font-semibold',
            ]"
          >
            {{ (plan.growth >= 0 ? '+' : '') + plan.growth + '%' }}
          </span>
        </div>
      </div>
      <div class="flex gap-4 flex-col">
        <div class="monthly-premium">
          <span class="monthly-premium-value">
            <span>Monthly premium</span>
            <span class="flex items-center">
              $<span class="number">{{
                USDollarFormatter(2)
                  .format(plan.premium ?? 0)
                  .replace('$', '')
              }}</span>
            </span>
          </span>
          <div
            @click="plan.open = !plan.open"
            v-if="isExpandable"
            :class="[
              'flex-grow-0 w-8 h-8 flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200',
              isExpandable && 'cursor-pointer',
            ]"
          >
            <Icon
              class="text-black"
              i-heroicons-outline:chevron-down
              v-if="!plan.open"
            />
            <Icon
              class="text-black"
              i-heroicons-outline:chevron-up
              v-if="plan.open"
            />
          </div>
        </div>
        <div :class="[plan.open ? 'table-wrapper' : 'flex gap-4 flex-col']">
          <div class="table" v-if="plan.open">
            <div class="item">
              Out-of-pocket maximum <span class="value">$0</span>
            </div>
            <div class="item">
              Inpatient hospital
              <span class="value">
                $390 copay per day: days 1-5 <br />
                $0 per stay for unlimited days
              </span>
            </div>
            <div class="item">
              Primary care provider (PCP) <span class="value">$0 copay</span>
            </div>
            <div class="item">
              Specialist <span class="value">$0 copay</span>
            </div>
            <div class="item">
              Annual medical deductible <span class="value">$0</span>
            </div>
            <div class="item">
              Prescription Drugs <span class="value">$0 copay</span>
            </div>
            <div class="item">
              Annual prescription dectutible <span class="value">$0</span>
            </div>
          </div>
          <div :class="['benefits flex gap-2 flex-col']">
            <div class="benefit-file-list" v-if="plan.open">
              <h3>Brochures</h3>
              <ul class="space-y-2">
                <li>
                  <a href="#" class="flex gap-2">
                    <Icon
                      i-heroicons-outline:document-text
                      class="w-6 h-6 text-blue"
                    />
                    Summary of Benefits
                  </a>
                </li>
                <li>
                  <a href="#" class="flex gap-2">
                    <Icon
                      i-heroicons-outline:document-text
                      class="w-6 h-6 text-blue"
                    />
                    Evidence of Coverage
                  </a>
                </li>
                <li>
                  <a href="#" class="flex gap-2">
                    <Icon
                      i-heroicons-outline:document-text
                      class="w-6 h-6 text-blue"
                    />
                    Medicare & You
                  </a>
                </li>
              </ul>
            </div>
            <div :class="['additional-benefits']">
              <h3>Additional Benefits</h3>
              <div class="flex gap-2 flex-wrap justify-start">
                <BenefitCard
                  v-for="(benefit, index) in plan.benefits"
                  :key="plan.plan_name + 'benefit' + index"
                  :benefit="benefit"
                />

                <!-- <BenefitPill
                  v-for="(benefit, index) in plan.benefits"
                  :key="plan.plan_name + 'benefit' + index"
                  :benefit="benefit"
                  :open="currentBenefitOpen === index"
                  :background-color="plan.carrierGroupColor"
                  @click="toggleCurrentBenefitOpen(index)"
                /> -->
              </div>
            </div>
          </div>
          <div
            :class="[
              'mt-4 grid-cols-2	grid gap-2 flex-grow',
              !plan.open ? '' : 'cta flex-grow',
            ]"
          >
            <Button
              size="sx"
              :class="[
                '!text-white px-4 !py-2 flex-grow',
                plan.open ? 'flex-grow max-w-1/2' : 'max-h-10',
              ]"
              to="/additional-benefits-grocery-2024"
              variant="darkBlue"
              :style="{ backgroundColor: plan.carrierGroupColor }"
            >
              <span> Learn More </span>
            </Button>
            <ClickToCall
              v-slot="{ handleClick }"
              :props="{ ctcLocation: 'shop-4-benefits last step' }"
            >
              <a
                :href="phone.uri"
                @click="handleClick"
                :class="[
                  'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white px-4 items-center shadow-lg',
                  plan.open && 'flex-grow',
                ]"
              >
                <Icon
                  i-heroicons-solid:phone
                  class="w-5 h-5 animate-shake text-white"
                />
                Call Now
              </a>
            </ClickToCall>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
a {
  @apply underline text-blue visited:text-violet-700active:text-violet-700;
}

.header {
  @apply text-black p-4 font-dm rounded-t-lg flex flex-col gap-2;
}

.header > span {
  @apply font-sans font-base text-xs flex items-center gap-2;
}

.body {
  @apply p-4 gap-4 flex flex-col;
}

.enrollments {
  @apply flex gap-2 py-2 px-4 bg-white rounded-lg text-black;
}
.monthly-premium {
  @apply text-lg flex gap-2 py-2 px-4 bg-white rounded-lg text-black justify-between;
}

.monthly-premium-value {
  @apply font-dm text-xs flex flex-col;
}

.monthly-premium-value .number {
  @apply text-4xl;
}
.table-wrapper {
  display: grid;
  grid-template-areas:
    'table'
    'benefits'
    'cta';
  @media screen and (min-width: 768px) {
    grid-template-areas:
      'table benefits'
      'cta cta';
  }
  @apply gap-4;
}

.table {
  @apply border border-gray-300 rounded-lg;
  grid-area: table;
}

.table > .item {
  @apply border-b border-gray-300 p-2 flex gap-2 justify-between last:border-b-0;
}

.table > .item > .value {
  @apply font-semibold text-right;
}
.benefits {
  grid-area: benefits;
}
.cta {
  grid-area: cta;
}

.additional-benefits {
  @apply mt-4;
}
h3 {
  @apply pb-4 text-blue-900 font-semibold;
}

ul {
  @apply space-y-2;
}

li {
  @apply flex gap-1;
}

li > span {
  @apply bg-sky-600 mt-1;
}
</style>
