<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center">
    Congratulations!
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Enroll with a licensed
    <span class="whitespace-nowrap">insurance agent.</span>
  </div>

  <div class="text-lg text-center">
    Call now to speak with a licensed insurance agent that can match you with a
    Medicare Advantage Plan from well-known carriers like Humana and
    UnitedHealthcare®.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
</template>
