<script setup lang="ts">
import allowanceCard from '../../assets/allowance-card-blank.png'
import allowanceCard3 from '../../assets/allowance-card-3.png'
import groceryHaul from '../../assets/grocery-haul-gif.gif'

useHead({
  title: `Additional Benefits OTC 2024 - ${brand.name}`,
})

const state = computed(() => {
  const state = states.find(
    (state) => state.code === 'CA'
    // ip.lookup?.stateCode
  )
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})

provide('state', state)
enterExperiment('additionalBenefitsOtc2024Headline')
enterExperiment('additionalBenefitsOtc2024Image')

const image = computed(() => {
  const image =
    getCurentValidExperiment(exp.value?.additionalBenefitsOtc2024Image) ??
    'allowanceCard'
  switch (image) {
    default:
      return allowanceCard
    case 'allowanceCard3':
      return allowanceCard3
    case 'groceryHaul':
      return groceryHaul
  }
})

const headline = computed(() => {
  const headline =
    getCurentValidExperiment(exp.value?.additionalBenefitsOtc2024Headline) ??
    'someMedicareBeneficiaries'
  switch (headline) {
    default:
      return `Some ${
        state.value?.name ?? 'state of user location'
      } Medicare beneficiaries could be eligible for a ${
        state.value?.minOtcLtd ?? '$500'
      } /yr OTC allowance with Medicare Advantage plans like Humana, UnitedHealthcare®, Anthem Blue Cross & Blue Shield, Aetna, Cigna Healthcare, Wellcare, Kaiser Permanente.`
    case 'stateMedicareAdvantagePlus':
      return `2024 ${state.value?.name ?? 'state'} Medicare Advantage Plans`
    case 'clickfor2024Medicare':
      return `Click for 2024 ${
        state.value?.name ?? 'state'
      } Medicare Advantage Plans`
    case 'youMayQualify':
      return `You May Qualify for $${
        state.value?.minOtcLtd ?? '$500'
      }/per year or more in Grocery Allowance with plans like Humana, UnitedHealthcare®, Anthem Blue Cross & Blue Shield, Aetna, Cigna Healthcare, Wellcare, Kaiser Permanente.`
  }
})
</script>

<template>
  <Layout header="simple3" footer="giveback" class="container">
    <template #banner>
      <Banner>
        On Medicare and Medicaid? *Available Medicare Advantage D-SNP
      </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div
        class="headline text-2xl text-center text-red font-medium font-dm"
        v-html="headline"
      >
      </div>
      <img
        :src="image"
        width="1024"
        height="1024"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Happy Senior lady in a dental chair"
      />
      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="otc2024"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicaid' },
            { id: 'otcallowance' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0524174_M",
      "bh": "MULTI-PLAN_HLWEB1023108_M"
    }
  }
}
</route>
