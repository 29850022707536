<script setup lang="ts">
import { Ref } from 'vue'
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)


const state = inject('state') as Ref<any>

onMounted(async () => {
  error.value = false
  try {
    plans.value = await getPlans({
      stateCode: state.value?.code,
      carrier_groups: [],
      amount: 10000,
      starting_from: 0,
      benefits: ['otc_summary'],
    })
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center">
      <Spinner />
    </div>
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl text-red text-center font-medium">
      Hay algunos errores al intentar encontrar planes para usted
    </div>
    <div class="text-xl text-center font-medium">
      Su <span class="whitespace-nowrap">Agente de Seguros</span> con licencia está listo para ayudar
    </div>
    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Haga clic para llamar
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
  <template v-else>
    <div
      class="text-xl text-red text-center font-medium"
      v-if="plans?.length && plans?.length > 0"
    >
      {{ plans?.length }} Planes Disponibles para Usted
    </div>
    <div class="text-xl text-red text-center font-medium" v-else>
      No se Encontraron Planes
    </div>

    <div class="text-xl text-center font-medium">
      Continuar con un
      <span class="whitespace-nowrap flex items-center justify-center gap-2">
        <div class="relative flex w-4 h-4">
          <div
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
          >
          </div>
          <div
            class="relative inline-flex rounded-full h-4 w-4 bg-green-500"
          ></div>
        </div>
        Agente de Seguros con Licencia
      </span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Haga clic para llamar
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>