<script setup lang="ts">
import { Ref } from 'vue'
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)

const state = inject('state') as Ref<any>
const allowanceBenefits = ref<string>('')

onMounted(async () => {
  error.value = false
  try {
    plans.value = await getPlans({
      stateCode: state.value?.code,
      carrier_groups: [],
      amount: 10000,
      starting_from: 0,
      benefits: ['otc_summary'],
    })
    plans.value = plans.value.sort((planA, planB) => {
      return planB.premium - planA.premium
    })
    allowanceBenefits.value = plans.value[0].benefits
      .map((benefit) => benefit.name)
      .join(', ')
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center">
      <Spinner />
    </div>
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl text-red text-center font-medium">
      There's some errors while trying to find plans for you
    </div>
    <div class="text-xl text-center font-medium">
      Your licensed
      <span class="whitespace-nowrap">Insurance Agent</span>
      is ready to help
    </div>
    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
  <template v-else>
    <div
      class="text-xl text-red text-center font-medium"
      v-if="plans?.length && plans?.length > 0"
    >
      We found plans near you with {{ allowanceBenefits }} allowance benefits.
      Click to Call.
    </div>
    <div class="text-xl text-red text-center font-medium" v-else>
      Sorry! <br />

      We could not find plans near you with OTC allowance benefits.
    </div>

    <div class="text-xl text-center font-medium">
      <span class="whitespace-nowrap flex items-start justify-center gap-2">
        <div class="flex w-4 h-4 mt-2 flex items-start justify-start">
          <div
            class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-green-400 opacity-75"
          >
          </div>
          <div class="inline-flex rounded-full h-4 w-4 bg-green-500"></div>
        </div>
        CALL A LICENSED <br />INSURANCE AGENT
      </span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>

    <div class="text-xl px-12 text-center">
      Benefits described above are available with Humana, UnitedHealthcare®,
      Anthem Blue Cross & Blue Shield, Aetna, Cigna Healthcare, Wellcare, Kaiser
      Permanente
    </div>
  </template>
</template>
<!-- <template v-else>
    <div class="flex flex-col items-center gap-4">
      <StateMap
        class="w-56 bg-gray-300"
        :state-code="state.code"
        v-if="state && state.code"
        :state-name="state.name"
      />
      <ClickToCall :props="{ ctcLocation: 'last-step' }">
        <template #text>
          {{ phone.formatted }}
          <br />
          711, 24/7
        </template>
      </ClickToCall>
      <div class="text-xl text-center font-medium">
        Licensed Insurange Agent Available
      </div>
    </div>
  </template> -->
