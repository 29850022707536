<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const emit = defineEmits(['selectPlan'])

const props = defineProps<{
  plan: PlanRowView
  showCallButton?: boolean
}>()

const filteredBenefits = computed(() =>
  props.plan.benefits.filter((benefit) => benefit.name !== 'OTC')
)
const otcBenefit = computed(() =>
  props.plan.benefits.find((benefit) => benefit.name === 'OTC')
)

const firstTwoBenefits = computed(() => {
  const arrayBenefits = []
  if (filteredBenefits.value.length >= 2) {
    arrayBenefits.push(filteredBenefits.value.shift())
    arrayBenefits.push(filteredBenefits.value.shift())
  }
  return arrayBenefits
})

const benefitsOpen = ref(false)
const onSelectPlan = () => {}
</script>

<template>
  <div class="flex flex-col rounded-2xl shadow-lg gap-4 pb-4">
    <div
      class="header flex justify-between items-start p-4 rounded-t-xl"
      :style="{ backgroundColor: plan.carrierGroupColor }"
    >
      <h2 class="text-xl text-white font-dm">
        {{ plan.carrier }} <br />
        <span class="text-sm text-white font-semibold font-sans">{{
          plan.plan_name
        }}</span>
      </h2>
      <input
        v-if="false"
        type="checkbox"
        class="w-4 h-4"
        @change="emit('selectPlan', plan.plan_id)"
      />
    </div>
    <div class="flex justify-between items-center px-4">
      <div class="flex flex-col gap-2">
        <div class="flex gap-1">
          <img
            src="../assets/star-green.png"
            v-for="star in Math.ceil(plan.stars)"
            class="w-4 h-4"
          />
          <img
            src="../assets/star-gray.png"
            class="w-4 h-4"
            v-for="grey in 5 - Math.ceil(plan.stars)"
          />
        </div>
        <div class="bg-red-500 text-white text-center rounded-lg px-2 py-1">
          Medicare
        </div>
      </div>
      <span class="monthly-premium-value">
        <span class="flex items-center">
          $<span class="number">{{
            USDollarFormatter(2)
              .format(plan.premium ?? 0)
              .replace('$', '')
          }}</span>
        </span>
        <span>Monthly premium</span>
      </span>
    </div>

    <div class="flex justify-between px-4">
      <div></div>
      <div class="enrollments">
        <span> {{ plan.enrollments }} members </span>
        <span
          :class="[
            plan.growth >= 0 ? 'text-green-600' : 'text-orange-600',
            'inline-block ml-2 font-semibold',
          ]"
        >
          {{ (plan.growth >= 0 ? '+' : '') + plan.growth + '%' }}
        </span>
      </div>
    </div>
    <div class="benefits">
      <div
        class="flex flex-col p-4 gap-4 bg-green-500 rounded-lg text-white"
        v-if="otcBenefit"
      >
        <div class="flex gap-4 items-center">
          <img src="../assets/groceries.png" class="w-8 h-8" />
          <h3>{{ otcBenefit.name }}</h3>
        </div>
        <span class="text-2xl font-semibold" v-html="otcBenefit.description" />
      </div>
      <div class="grid grid-cols-2 gap-2">
        <BenefitCardV3
          :benefit="firstTwoBenefits[0]"
          v-if="!!firstTwoBenefits[0]"
        >
        </BenefitCardV3>
        <BenefitCardV3
          :benefit="firstTwoBenefits[1]"
          v-if="!!firstTwoBenefits[1]"
        >
        </BenefitCardV3>
      </div>
    </div>
    <!-- <div class="flex mx-4 px-4 bg-gray-200 rounded-lg">
      <div class="flex justify-between flex-grow flex-col">
        <div
          class="flex justify-between py-4 cursor-pointer"
          @click="benefitsOpen = !benefitsOpen"
        >
          <div class="font-semibold">Additional Benefits</div>
          <Icon
            class="text-black"
            i-heroicons-outline:chevron-down
            v-if="!benefitsOpen"
          />
          <Icon
            class="text-black"
            i-heroicons-outline:chevron-up
            v-if="benefitsOpen"
          />
        </div>

        <div class="table" v-if="benefitsOpen">
          <div class="item" v-for="benefit in filteredBenefits">
            {{ benefit.name }}
            <span class="value" v-html="benefit.description" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex mx-4 px-4 bg-gray-200 rounded-lg">
      <div class="flex justify-between flex-grow flex-col">
        <div
          class="flex justify-between py-4 cursor-pointer"
          @click="plan.open = !plan.open"
        >
          <div class="font-semibold">Basic Coverage Details</div>
          <Icon
            class="text-black"
            i-heroicons-outline:chevron-down
            v-if="!plan.open"
          />
          <Icon
            class="text-black"
            i-heroicons-outline:chevron-up
            v-if="plan.open"
          />
        </div>

        <div class="table" v-if="plan.open">
          <div class="item">
            Out-of-pocket maximum <span class="value">$0</span>
          </div>
          <div class="item">
            Inpatient hospital
            <span class="value">
              $390 copay per day: days 1-5 <br />
              $0 per stay for unlimited days
            </span>
          </div>
          <div class="item">
            Primary care provider (PCP) <span class="value">$0 copay</span>
          </div>
          <div class="item">
            Specialist <span class="value">$0 copay</span>
          </div>
          <div class="item">
            Annual medical deductible <span class="value">$0</span>
          </div>
          <div class="item">
            Prescription Drugs <span class="value">$0 copay</span>
          </div>
          <div class="item">
            Annual prescription dectutible <span class="value">$0</span>
          </div>
        </div>
      </div>
    </div> -->
    <div
      :class="[
        showCallButton
          ? 'mt-4 grid-cols-2 grid gap-2 flex-grow px-4'
          : 'flex px-4',
      ]"
    >
      <Button
        size="sx"
        class="!text-black px-4 !py-2 flex-grow !bg-gray-300"
        to="/additional-benefits-grocery-2024"
      >
        <span> Learn More </span>
      </Button>
      <ClickToCall
        v-if="showCallButton"
        v-slot="{ handleClick }"
        :props="{ ctcLocation: 'shop-4-benefits last step' }"
      >
        <a
          :href="phone.uri"
          @click="handleClick"
          :class="[
            'flex justify-center p-2 bg-red rounded-md flex gap-2 !text-white px-4 items-center shadow-lg',
            plan.open && 'flex-grow',
          ]"
        >
          <Icon
            i-heroicons-solid:phone
            class="w-5 h-5 animate-shake text-white"
          />
          Call Now
        </a>
      </ClickToCall>
    </div>
  </div>
</template>

<style scoped>
.monthly-premium-value {
  @apply font-dm text-sm flex text-black items-center gap-1;
}

.monthly-premium-value .number {
  @apply text-4xl;
}

.enrollments {
  @apply flex gap-2 text-black;
}

.table > .item {
  @apply border-b border-gray-300 p-2 flex gap-2 justify-between last:border-b-0 items-center;
}

.table > .item > .value {
  @apply font-semibold text-right;
}
.benefits {
  @apply flex flex-col gap-2 px-4;
}
</style>
