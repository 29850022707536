<script setup lang="ts">
const { value: hasInterest } = useField('hasInterest')

const minGiveback = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
    ?.minGiveback
})

const maxGiveback = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
    ?.maxGiveback
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Do you want to shop for plans with between
    {{ minGiveback ? `$${minGiveback}` : '$0' }} and
    {{ maxGiveback ? `$${maxGiveback}` : '$82' }} in monthly premium reduction
    benefits?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="hasInterest = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="hasInterest = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="hasInterest = null"
    >
      Skip
    </Button>
  </div>
</template>
