<script setup lang="ts">
useHead({
  title: `Additional benefits buyback - ${brand.name}`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="ping" footer="2025" class="container">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Turning 65? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-semibold text-center">
        Some Americans with Medicare A&B in
        {{ ip.lookup?.stateName || 'America' }}
        are getting Medicare Advantage Plans with
        <span class="text-red">
          additional benefits like OTC, Flexible Spending,
          <span class="whitespace-nowrap">or Dental.</span>
        </span>
      </div>

      <img
        src="../../assets/buyback-2024.jpeg"
        width="1200"
        height="627"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 relative"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="buyback"
            class="py-6 px-4 sm:px-6 !max-w-3xl"
            scrollBehavior="auto"
            :steps="[
              { id: 'medicareab' },
              { id: 'sorry', if: values?.haveMedicare === false },
              { id: 'medicaid' },
              { id: 'zipcode' },
              { id: 'call' },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>

      <div class="text-lg text-center">
        Shop plans from carriers like: Humana, UnitedHealthcare®.
      </div>

      <div class="text-center"> Displaying results for [county], [state] </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624201_M",
      "m10": "MULTI-PLAN_HLWEB0624180_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0624201_M",
      "m10": "MULTI-PLAN_HLWEB0624180_M"
    }
  }
}
</route>
