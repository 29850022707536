<script setup lang="ts">
const { value: benefit } = useField('benefit')
</script>

<template>
  <div class="text-3xl text-center flex flex-col gap-4 !mt-0">
    <div class="text-3xl text-center font-bold">
      What benefits are you shopping for?
    </div>
    <div class="text-3xl text-center">
      Compare Medicare Advantage Plans Online
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4 !mt-0">
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="benefit = 'grocery'"
    >
      Grocery / OTC / Flex Allowance
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="benefit = 'premium-giveback'"
    >
      Part B Premium Rebate
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="benefit = 'dental'"
    >
      Dental
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="benefit = 'vision'"
    >
      Vision
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="benefit = 'rx-prescription'"
    >
      Rx / Prescription
    </Button>
    <Button wide size="sm" type="submit" variant="gray" @click="benefit = null">
      Skip (show all plans)
    </Button>
  </div>
  <FooterPhone2 />
</template>
