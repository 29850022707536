<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div
    class="text-3xl sm:text-4xl text-center flex flex-col gap-4 !mt-0 font-semibold"
  >
    <div> Do you have Medicare Parts A & B?</div>
    <FormProgressSteps />
    <div class="text-xl md:text-2xl">
      This is required to get a Medicare Advantage plan
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
    <BubbleTip> Let's search for plans with additional benefits </BubbleTip>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Skip (show all plans)
    </Button>
  </div>
  <FooterPhone2 />
</template>
