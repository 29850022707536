<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')

const state = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
})
</script>

<template>
  <div class="text-3xl sm:text-4xl text-center !mt-0 font-semibold">
    Do you have Medicaid?
  </div>

  <FormProgressSteps />
  <div class="text-3xl text-center flex flex-col gap-4 !mt-0">
    <div
      v-if="state && state.medicaid !== 'Medicaid'"
      class="text-2xl md:text-3xl"
    >
      In {{ state.name }} Medicaid is also called {{ state.medicaid }}
    </div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = true"
    >
      Yes
    </Button>

    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = false"
      class="flex-grow-0"
    >
      No
    </Button>

    <BubbleTip>
      You may be eligible for a special kind of plan that only people on
      Medicaid can get.
    </BubbleTip>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Skip (show all plans)
    </Button>
  </div>
  <FooterPhone2 />
</template>
