<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')
</script>

<template>
  <div class="text-xl text-red text-center font-medium">
    Verificar elegibilidad
  </div>

  <div class="text-xl text-center font-medium"> ¿Tiene Medicaid? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="haveMedicaid = true">
      Sí
    </Button>
    <Button wide size="sm" type="submit" @click="haveMedicaid = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Omitir
    </Button>
  </div>
</template>