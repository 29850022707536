<script setup lang="ts">
import { find } from 'lodash'
import { PlanRowView, getPlans } from '~/modules/plan'

const stateCode = ref()
const showDSNP = ref(true)
const loading = ref(false)
const filtering = ref(false)
const error = ref(false)
const startingFrom = ref(0)
const loadMoreVisible = ref(true)
const selectedPlans = ref<PlanRowView[]>([])

const stateName = computed(() => {
  return states.find((state) => state.code === stateCode.value)?.name
})

const getStateCode = async () => {
  const queryState = query.state?.toUpperCase()
  if (queryState && states.map((state) => state.code).includes(queryState)) {
    stateCode.value = queryState
    return
  }
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  const ipLookupStateCode = ip.lookup?.stateCode
  if (ipLookupStateCode) {
    stateCode.value = ipLookupStateCode
    return
  }
}

const plans = ref<PlanRowView[]>([])
const filteredPlans = ref<PlanRowView[]>([])

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 10
  loading.value = true
  error.value = false
  await getStateCode()
  plans.value = await getPlans({
    zipCode: zipCode.value,
    starting_from: startingFrom,
    amount: startingFrom + amount,
  })
  loading.value = false
  if (plans.value.length === 0) {
    loadMoreVisible.value = false
    return
  }
  filteredPlans.value = [...filteredPlans.value, ...plans.value]
}

onMounted(() => loadData({ startingFrom: 0, amount: 10 }))

const zipCode = computed(() => {
  return forms.search4Benefits2024?.zipcode ?? query.zip
})

watch(filteredPlans, () => {
  if (filteredPlans.value.length < 10) {
    loadMoreVisible.value = false
  }
})

const toggle = () => {
  filtering.value = true

  showDSNP.value = !showDSNP.value
  setTimeout(() => {
    if (showDSNP.value) {
      filteredPlans.value = plans.value
    } else {
      filteredPlans.value = plans.value.filter(
        (plan) => plan.plan_type !== 'D-SNP'
      )
    }

    filtering.value = false
  }, 800)
}

const nextPlans = () => {
  startingFrom.value += 10
  loadData({ startingFrom: startingFrom.value })
}

const toggleSelectPlan = (plan_id: string) => {
  const selectedPlan = selectedPlans.value.find(
    (plan) => plan.plan_id === plan_id
  )
  if (selectedPlan) {
    selectedPlans.value = selectedPlans.value.filter(
      (plan) => plan.plan_id !== plan_id
    )
  } else {
    const findPlan = filteredPlans.value.find(
      (plan) => plan.plan_id === plan_id
    )
    if (findPlan) {
      selectedPlans.value.push(findPlan)
    }
  }

  if (selectedPlans.value.length > 0) {
    showFooterPhone.value = true
  }
}

const showFooterPhone = ref(true)
</script>

<template>
  <div v-if="loading" class="bg-white rounded-md shadow p-8">
    <Spinner class="mx-auto" />
  </div>
  <div v-if="error" class="bg-white rounded-md shadow p-8">
    There's some issue loading your data, please try again later.

    <Button wide @click="loadData"> Try Again</Button>
  </div>
  <div class="text-center flex flex-col gap-4" v-if="!loading && !error">
    <div className="text-3xl md:text-2xl font-semibold">
      Good news! We found some plans in your area.
    </div>

    <div class="text-2xl md:text-xl text-gray-600 flex justify-center">
      <Toggle
        id="showDSNP"
        label="Show Medicaid Plans"
        v-model="showDSNP"
        @update:modelValue="toggle"
      />
    </div>
  </div>
  <div v-if="filtering" class="bg-white rounded-md shadow p-8">
    <Spinner class="mx-auto" />
  </div>
  <div
    v-if="!loading && !error && !filtering"
    class="flex flex-col items-center gap-4 !mt-0"
  >
    <PlanList
      :plans="filteredPlans"
      :plan-card-version="3"
      :show-call-button="true"
    />
    <Button wide v-if="loadMoreVisible" @click="nextPlans">Load More</Button>
  </div>

  <FooterPhone3
    v-if="showFooterPhone"
    @close="showFooterPhone = false"
    :selected-plans-length="selectedPlans.length"
  />
</template>
