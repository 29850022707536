<script setup lang="ts">
import { PlanRowView, getPlans } from '~/modules/plan'

const stateCode = ref()
const showDSNP = ref(Boolean(forms.search4Benefits2024?.haveMedicaid))
const loading = ref(false)
const filtering = ref(false)
const error = ref(false)
const startingFrom = ref(0)
const loadMoreVisible = ref(true)
const currentPlans = ref<PlanRowView[]>([])
const isPaidUser = ref(false)

const stateName = computed(() => {
  return states.find((state) => state.code === stateCode.value)?.name
})

const getStateCode = async () => {
  const queryState = query.state?.toUpperCase()
  if (queryState && states.map((state) => state.code).includes(queryState)) {
    stateCode.value = queryState
    return
  }
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  const ipLookupStateCode = ip.lookup?.stateCode
  if (ipLookupStateCode) {
    stateCode.value = ipLookupStateCode
    return
  }
}

const filteredPlans = ref<PlanRowView[]>([])

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 10
  loading.value = true
  error.value = false
  await getStateCode()
  const plans = await getPlans({
    zipCode: zipCode.value,
    starting_from: startingFrom,
    amount: amount,
  })
  currentPlans.value = [...currentPlans.value, ...plans]
  loading.value = false
  if (plans.length === 0) {
    loadMoreVisible.value = false
    return
  }
  filteredPlans.value = showDSNP
    ? currentPlans.value
    : currentPlans.value.filter((plan) => plan.plan_type !== 'D-SNP')
}

onMounted(() => {
  loadData({ startingFrom: 0, amount: 10 })

  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const zipCode = computed(() => {
  return forms.search4Benefits2024?.zipcode ?? query.zip
})

const firstPlan = computed(
  () => filteredPlans.value.length > 0 && filteredPlans.value.shift()
)

watch(filteredPlans, () => {
  if (filteredPlans.value.length < 10) {
    loadMoreVisible.value = false
  }
})

const toggle = () => {
  filtering.value = true
  showDSNP.value = !showDSNP.value

  setTimeout(() => {
    if (showDSNP.value) {
      filteredPlans.value = currentPlans.value
    } else {
      filteredPlans.value = currentPlans.value.filter(
        (plan) => plan.plan_type !== 'D-SNP'
      )
    }

    filtering.value = false
  }, 800)
}

const nextPlans = () => {
  startingFrom.value += 10
  loadData({ startingFrom: startingFrom.value })
}

const footerVisible = ref(true)
</script>

<template>
  <div v-if="loading" class="bg-white rounded-md shadow p-8">
    <Spinner class="mx-auto" />
  </div>
  <div v-if="error" class="bg-white rounded-md shadow p-8">
    There's some issue loading your data, please try again later.

    <Button wide @click="loadData">Try Again</Button>
  </div>
  <div
    class="text-center flex flex-col gap-4"
    v-if="!loading && !error && firstPlan"
  >
    <div className="text-3xl md:text-2xl font-semibold">
      Good news! We found some plans in your area.
    </div>
    <div class="text-2xl md:text-xl text-gray-600">
      Here are some plans available in <strong>{{ stateName }}</strong> based on
      your filters.
    </div>

    <div class="text-2xl md:text-xl text-gray-600 flex justify-center">
      <Toggle
        id="showDSNP"
        label="Show Medicaid Plans"
        v-model="showDSNP"
        @update:modelValue="toggle"
      />
    </div>
  </div>
  <div v-if="filtering" class="bg-white rounded-md shadow p-8">
    <Spinner class="mx-auto" />
  </div>
  <div
    v-if="!loading && !error && !filtering"
    class="space-y-2 flex flex-col items-center"
  >
    <PlanCard
      v-if="!!firstPlan"
      :plan="firstPlan"
      :is-expandable="false"
      :location="`search-4-benefits last step`"
      :show-call-button="isPaidUser"
    />

    <div v-if="filteredPlans.length > 0" class="text-xl pt-4">
      Other plans that you might be interested
    </div>
    <div class="space-y-2">
      <PlanList
        :plans="filteredPlans"
        :plan-card-version="1"
        :show-call-button="isPaidUser"
      />
    </div>
    <Button wide v-if="loadMoreVisible" @click="nextPlans">Load More</Button>
  </div>
  <FooterPhone3 @close="footerVisible = false" v-if="footerVisible" />
</template>
