<script setup lang="ts">
const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl sm:text-2xl text-red text-center font-medium">
    Looks like there may be Medicare Advantage D-SNP plans with Allowance
    benefits in your area. Some plans include
    <b> ${{ minOtcLtd }} per year or more </b> to use towards groceries.
  </div>

  <div class="text-xl sm:text-2xl text-center font-medium">
    Call {{ brand.name }} now to speak to a
    <span class="whitespace-nowrap">licensed insurance agent</span>
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }">
    <template #text>
      Call {{ phone.formatted }} <br />
      TTY 711, 24/7
    </template>
  </ClickToCall>

  <div class="text-center"> Licensed sales agents are standing by now </div>
</template>
