<script setup lang="ts">
const isProd = import.meta.env.MODE === 'production'

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const top5Otc = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500
  )
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <div v-if="isPaidUser" class="text-3xl sm:text-4xl text-center">
    Looks like you may qualify for ${{ minOtcLtd }} per year or more to spend on
    groceries!
  </div>
  <div v-else class="text-3xl sm:text-4xl text-center">
    Finish up with a licensed insurance agent.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div
    v-if="isPaidUser && isProd"
    class="text-lg sm:text-xl font-medium text-center"
  >
    Act quickly: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
