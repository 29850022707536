<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center">
    Do you have Medicare <span class="whitespace-nowrap">Parts A & B?</span>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
