<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center"> What's your ZIP code? </div>

  <Field
    type="zip"
    name="zipcode"
    label="ZIP code"
    rules="required"
    :initialValue="query.zip"
  />

  <div class="space-y-3">
    <FormButtonNext />
    <Button
      wide
      size="sm"
      variant="gray"
      @click="$router.push('/click-2-lower-premiums/interested')"
    >
      Skip
    </Button>
  </div>
</template>
