<template>
  <div
    className="relative bg-blue-500 text-white p-4 rounded-2xl w-full text-sm mt-4"
  >
    <div
      className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 rotate-45 w-4 h-4"
    ></div>
    <p><slot /> </p>
  </div>
</template>
