<script setup lang="ts">
const isProd = import.meta.env.MODE === 'production'

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center">
    ¡Felicitaciones!
  </div>

  <template v-if="isPaidUser">
    <div class="text-3xl sm:text-4xl text-center">
      Parece que puedes calificar para ${{ minOtcLtd }} por año o más para
      gastar en alimentos!
    </div>

    <div class="text-3xl sm:text-4xl text-center">
      Llama a la Línea de Ayuda de Beneficios
    </div>
  </template>
  <template v-else>
    <div class="text-3xl sm:text-4xl text-center">
      Complete su solicitud con un agente de seguros autorizado.
    </div>
  </template>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div
    v-if="isPaidUser && isProd"
    class="text-lg sm:text-xl font-medium text-center"
  >
    Actúa rápidamente: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
