<script setup lang="ts">
import type { PlanRowView } from '~/modules/plan'

const loading = ref(true)
const plans = ref<PlanRowView[] | null>(null)

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  if (ip.lookup?.stateCode) {
    try {
      const res = await getPlans({
        starting_from: 0,
        amount: 3,
        benefits: ['giveback_summary'],
        stateCode: ip.lookup.stateCode,
      })
      if (res.length) {
        plans.value = res
      }
    } catch (error) {}
  }

  loading.value = false
})
</script>

<template>
  <Spinner v-if="loading" class="mx-auto" />
  <template v-else-if="plans">
    <div
      v-for="(plan, index) in plans"
      class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-4"
    >
      <div class="text-xl text-blue font-medium text-center">
        {{ plan.carrier_group }}
      </div>

      <div class="text-lg font-medium text-center">
        PLAN {{ index + 1 }} in {{ ip.lookup?.stateCode }} <br />
        ${{
          plan.benefits?.find((b) => b.name === 'Giveback')?.amount || '???'
        }}/yr in premium savings
      </div>

      <FormButtonNext> Learn More </FormButtonNext>
    </div>
  </template>
  <div v-else>
    <FormButtonNext> Learn More </FormButtonNext>
  </div>
</template>
