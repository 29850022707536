<script setup lang="ts"></script>

<template>
  <div class="text-2xl sm:text-3xl text-center"> Plans list </div>
  <div
    class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
  >
    <div class="text-xl sm:text-2xl text-center">
      {plan-marketing-name} - premium plan
    </div>
    <div class="text-xl sm:text-2xl text-center"> {plan.name} </div>

    <div class="text-xl sm:text-2xl text-center">
      {plan.enrollments} (i) {plan.enrollments.pct_change}
    </div>
    <div class="text-lg sm:text-xl font-semibold"> Benefits </div>

    <div class="items-center gap-4 grid lg:grid-cols-2">
      <div className="flex gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Transportation </div>
      </div>
      <div class="flex-1 text-lg font-semibold"> 4 Rides per year </div>
    </div>
    <div class="items-center gap-4 grid lg:grid-cols-2">
      <div className="flex gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Over-the-Counter </div>
      </div>
      <div class="flex-1 text-lg font-semibold"> $100 per year </div>
    </div>
    <div class="items-center gap-4 grid lg:grid-cols-2">
      <div className="flex gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Over-the-Counter </div>
      </div>
      <div class="flex-1 text-lg font-semibold"> $100 per year </div>
    </div>
  </div>
</template>
