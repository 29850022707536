<script setup lang="ts">
const { value: wantMoneyBack } = useField('wantMoneyBack')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minGiveback && state.maxGiveback) {
    return {
      code: state.code,
      name: state.name,
      minGiveback: `$${Math.round(12 * state.minGiveback)}`,
      maxGiveback: `$${Math.round(12 * state.maxGiveback)}`,
    }
  }
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    <template v-if="state">
      Do you want $$$ back
      on your Social Security Check?
    </template>
    <template v-else>
      Do you want to shop for plans with additional benefits?
    </template>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantMoneyBack = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantMoneyBack = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantMoneyBack = null"
    >
      Skip
    </Button>
  </div>
</template>
