<script setup lang="ts">
const { onSubmit } = inject<any>('form')

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.maxOtc && state.top5Otc) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      maxOtc: `$${state.maxOtc}`,
      top5Otc: `$${state.top5Otc}`,
    }
  }
})
</script>

<template>
  <div class="text-2xl sm:text-3xl text-center"> New Medicare Update </div>

  <div class="text-lg font-medium text-center">
    <template v-if="!isMobile || !isPaidUser || !state">
      Please hold while we check for
      <span class="text-blue-600">2024 Medicare Advantage Plans</span> in your
      area.
    </template>
    <template v-else>
      Seniors are receiving
      <span class="text-blue-600"> up to {{ state.maxOtc }} </span>
      to spend on Groceries, Prescriptions, Dental care,
      <span class="whitespace-nowrap">and more!</span>
    </template>
  </div>

  <Spinner class="mx-auto" />

  <Checklist
    :items="[
      {
        text: 'Submitting your responses',
        icon: 'check',
      },
      {
        text: 'Checking if you qualify',
        icon: 'check',
      },
      {
        text: 'Connecting with a specialist',
        icon: 'check',
      },
    ]"
    :speed="15"
    @done="onSubmit()"
  />
</template>
