<template>
  <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
    If you want to know more about the benefits of Medicare Advantage, please
    call us at
    <ClickToCall v-slot="{ handleClick }">
      <Link :to="phone.uri" @click="handleClick">{{ phone.formatted }}</Link
      >.
    </ClickToCall>
  </div>
</template>
