<script setup lang="ts">
import { Ref } from 'vue'

const { value: learnMore } = useField('learnMore')
const state = inject('state') as Ref<any>
</script>

<template>
  <div class="text-2xl text-blue text-center font-medium">
    ¿Desea obtener más información sobre los beneficios flexibles de asignación de OTC de
    {{ state.minOtcLtd ?? '$500' }}/por año o más?
  </div>
  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="learnMore = true">
      Sí
    </Button>
    <Button wide size="sm" type="submit" @click="learnMore = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="learnMore = null"
    >
      Omitir
    </Button>
  </div>
</template>