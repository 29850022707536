<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>
</script>

<template>
  <template v-if="variant === 'default'">
    <div class="text-2xl sm:text-3xl">
      Are you shopping for Medicare Insurance plans? A licensed insurance agent
      can check your eligibility.
    </div>

    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
    >
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Medicare Advantage Plans </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Medicare Supplement Plans </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg"> Medicare Part D Plans </div>
      </div>
    </div>

    <FormButtonNext> Get Started </FormButtonNext>
  </template>

  <template v-if="variant === 'groceryAllowance900'">
    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-6"
    >
      <div class="text-2xl sm:text-3xl">
        Let's see if you qualify for a Medicare Advantage Plan with up to $900
        in Healthy Grocery Allowance°
      </div>

      <FormButtonNext> See if I qualify </FormButtonNext>
    </div>
  </template>

  <template v-if="variant === 'spendingAllowances3300'">
    <div class="text-2xl sm:text-3xl">
      See if you qualify for extra benefits on a Medicare Advantage Plan that
      may include up to:
    </div>

    <div
      class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
    >
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $1,200 back on your social security check*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $1,600 allowance for over-the-counter items*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $900 in healthy foods grocery pre-paid debit card*
        </div>
      </div>
      <div class="flex items-center gap-4">
        <Icon i-heroicons-outline:check class="text-blue" />
        <div class="flex-1 text-lg">
          $400 allowance for glasses or contact lenses*
        </div>
      </div>
    </div>

    <FormButtonNext> See if I qualify </FormButtonNext>
  </template>
</template>
