<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'
import variant1 from '../../assets/dental-low-copay-1.png'
import variant2 from '../../assets/dental-low-copay-2.png'
import variant3 from '../../assets/dental-low-copay-3.png'
import variant4 from '../../assets/dental-low-copay-4.png'
import variant5 from '../../assets/dental-low-copay-5.png'
import variant6 from '../../assets/dental-low-copay-6.png'

useHead({
  title: `Zero Zero Dental Coverage - ${brand.name}`,
})

const stateCode = ref()
const stateName = ref()
const plans = ref<PlanRowView[]>([])
const filteredPlans = ref<PlanRowView[]>([])
const loading = ref(false)
const error = ref(false)
const loadMoreVisible = ref(true)
const startingFrom = ref(0)

const image = ref(variant1)
const text = ref(`Low Co-Pay DENTAL COVERAGE <br /> AS LOW AS $0/mo Premium`)

const getStateCode = async () => {
  const queryState = query.state?.toUpperCase()
  if (queryState && states.map((state) => state.code).includes(queryState)) {
    stateCode.value = queryState
    const state = states.find((state) => state.code === queryState)
    stateName.value = state?.name
    return
  }
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  const ipLookupStateCode = ip.lookup?.stateCode
  if (ipLookupStateCode) {
    stateCode.value = ipLookupStateCode
    stateName.value = ip.lookup?.stateName
    return
  }
}

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 10
  loading.value = true
  error.value = false
  await getStateCode()
  plans.value = await getPlans({
    zipCode: zipCode.value,
    starting_from: startingFrom,
    amount: startingFrom + amount,
  })
  loading.value = false
  if (plans.value.length === 0) {
    loadMoreVisible.value = false
    return
  }
  filteredPlans.value = [...filteredPlans.value, ...plans.value]
}

onMounted(() => {
  loadData({ startingFrom: 0, amount: 10 })
  enterExperiment('zeroZeroDental')
  enterExperiment('zeroZeroDentalText')
  let key = 1
  if (exp.value?.zeroZeroDental) {
    for (const k of Object.keys(exp.value.zeroZeroDental)) {
      if ((exp.value.zeroZeroDental as any)[k as any] === true) {
        let value = parseInt(k.replace('variant', ''), 10)
        key = value
      }
    }
  }

  const imageObj = [
    '',
    variant1,
    variant2,
    variant3,
    variant4,
    variant5,
    variant6,
  ]
  image.value = imageObj[key]
  let textKey = 1
  const texts = [
    '',
    `Low Co-Pay DENTAL COVERAGE <br /> AS LOW AS $0/mo Premium`,
    `Some plans include <br /> 
Flexible Dental Allowance`,
    `Some $0 or Low Co-pay Plans <br /> 
Premiums as Low as $0/mo`,
    `Coverage for routine visits,<br /> 
Some Major dental, or dentures`,
    `Dentures, Some Major Dental,<br /> 
Or Routine Dental Visits`,
    `YOU COULD PAY LESS OUT OF POCKET<br /> 
FOR TRIPS TO THE DENTIST`,
  ]

  if (exp.value?.zeroZeroDentalText) {
    for (const k of Object.keys(exp.value.zeroZeroDentalText)) {
      if ((exp.value.zeroZeroDentalText as any)[k as any] === true) {
        let value = parseInt(k.replace('variant', ''), 10)
        textKey = value
      }
    }
  }
  text.value = texts[textKey]
})

const zipCode = computed(() => {
  return forms.search4Benefits2024?.zipcode ?? query.zip
})

watch(filteredPlans, () => {
  if (filteredPlans.value.length < 10) {
    loadMoreVisible.value = false
  }
})

const nextPlans = () => {
  startingFrom.value += 10
  loadData({ startingFrom: startingFrom.value })
}
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-2xl text-center text-red font-semibold font-dm">
        2024 Medicare Advantage Plans
        <span class="whitespace-nowrap">w Dental Coverage</span>
      </div>

      <img
        aria-label="Elderly woman smiling in dental chair with text overlay: 'Dental Co-pay as Low as $0'. Call for dental insurance details."
        :src="image"
        width="1024"
        height="918"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div class="text-xl text-center font-medium" v-html="text"> </div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="zeroZeroDentalCoverage"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>

      <div class="text-xl text-blue text-center font-medium">
        Shop plans like Aetna, Humana, UnitedHealthcare®
      </div>

      <div class="flex justify-center">
        <div class="bg-blue-600/80 px-1.5 rounded-md text-white">
          Showing Results for {{ ip.lookup?.stateName || 'America' }}
        </div>
      </div>

      <div class="gap-4 flex flex-col" v-if="!loading && !error">
        <PlanList :plans="filteredPlans" :plan-card-version="1" />

        <Button wide v-if="loadMoreVisible" @click="nextPlans">
          Load More
        </Button>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["dental"],
    "brands": ["bh"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_HLWEB0124140_M"
  }
}
</route>
