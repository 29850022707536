<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Are you enrolled in
    {{
      states.find((state) => state.code === ip.lookup?.stateCode)?.medicaid ||
      'Medicaid'
    }}?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicaid = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Skip
    </Button>
  </div>
</template>
