<script setup lang="ts">
useHead({
  title: `Medicare Premium Reduction Listing 2096 Card - ${brand.name}`,
})
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl text-center font-medium"> MEDICARE IS NOT FREE </div>

      <img
        src="../../assets/card-medicare-2.png"
        width="1514"
        height="756"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div class="text-xl text-center font-medium">
        Most pay $174/mo for Medicare
      </div>

      <div class="text-xl text-center text-gray-500">
        Some Medicare Advantage Plans <br />
        <span class="font-medium text-gray-900"
          >Lower Your Part B Premium:</span
        >
      </div>

      <Form
        id="medicarePremiumReductionListing2096Card"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'start' },
          { id: 'medicareab' },
          { id: 'medicaid' },
          { id: 'zipcode' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh"],
    "smid": "MULTI-PLAN_HLWEB0124131_M",
    "xsmid": "MULTI-PLAN_HLWEB0124131_M",
    "robots": "noindex"
  }
}
</route>
