<script setup lang="ts">
import type { PlanRowView } from '~/modules/plan'

const loading = ref(true)
const plans = ref<PlanRowView[]>([])
const showAllPlans = ref<boolean>(false)

onMounted(async () => {
  loading.value = true
  try {
    const res = await getPlans({
      starting_from: 0,
      amount: 100,
      benefits: ['giveback_summary'],
      zipCode: forms.medicarePremiumReductionListingR2?.zipcode,
    })
    if (res.length) {
      plans.value = res.sort((a, b) => {
        const givebackA =
          a.benefits?.find((benefit) => benefit.name === 'Giveback')?.amount ??
          0
        const givebackB =
          b.benefits?.find((benefit) => benefit.name === 'Giveback')?.amount ??
          0
        return givebackB - givebackA
      })
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <Spinner v-if="loading" class="mx-auto" />

  <div
    v-for="plan in showAllPlans ? plans : plans.slice(0, 5)"
    class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-4"
  >
    <div class="grid grid-cols-[1fr_auto] gap-4">
      <div>
        <div class="text-xl text-blue font-medium">
          {{ plan.carrier_group }}
        </div>
        <div class="text-base text-gray-600 font-medium">
          {{ plan.plan_name }}
        </div>
      </div>
      <div class="text-right">
        <div class="text-xl text-red font-medium">
          ${{
            Math.round(
              (plan.benefits?.find((b) => b.name === 'Giveback')?.amount || 0) *
                12
            )
          }}/yr
        </div>
        <div class="text-lg font-medium">
          premium <br />
          reduction
        </div>
      </div>
    </div>

    <FormButtonNext> Learn More </FormButtonNext>
  </div>

  <Button
    v-if="plans.length > 5"
    wide
    variant="blue"
    @click="showAllPlans = !showAllPlans"
  >
    {{ showAllPlans ? 'Less' : 'More ' }} Plans
  </Button>
</template>
