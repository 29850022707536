<script setup lang="ts">
const { value: newToMedicare } = useField('newToMedicare')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Will you turn 65 or signing up for Medicare Part B in the next six months?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="newToMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="newToMedicare = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="newToMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
