<script setup lang="ts">
const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  enterExperiment('groceryAllowance2Image')
})
</script>

<template>
  <Layout :header="'blue'">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <main class="flex-1 container min-h-screen">
      <div class="max-w-3xl mx-auto space-y-4 py-4 relative">
        <Form
          id="groceryAllowance2"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'us' },
            { id: 'moneyForGroceries' },
            { id: 'loading' },
            { id: 'call' },
          ]"
          product="medicare"
        />
      </div>
      <div class="h-px bg-gray-200"> </div>
    </main>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["boomer", "bh", "med10","m10"],
    "robots": "noindex",
    "smid": {
      "boomer": "MULTI-PLAN_EHWEB082314_2024_M",
      "med10": "MULTI-PLAN_EHWEB082312_2024_M",
      "m10": "MULTI-PLAN_EHWEB082312_2024_M",
      "bh": "MULTI-PLAN_EHWEB082313_2024_M"
    }
  }
}
</route>
