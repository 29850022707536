<script setup lang="ts">
const state = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
})
</script>

<template>
  <div class="text-2xl sm:text-3xl text-center">
    Some Medicare members <span v-if="state"> in {{ state.name }} </span> may be
    eligible for ${{ state?.minOtcLtd ?? 500 }}/yr or more towards plan-approved
    OTC products with some Medicare Advantage Plans.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }">
    <template #text>
      Call {{ phone.formatted }} <br />
      TTY 711, 24/7
    </template>
  </ClickToCall>
</template>
