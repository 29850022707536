<script setup lang="ts">
useHead({
  title: `Additional benefits PDP 2024 - ${brand.name}`,
})

const isMounted = useMounted()

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const showDefaultExperience = computed(() => {
  return !isMobile.value && import.meta.env.MODE === 'production'
})
</script>

<template>
  <Layout header="simple3" footer="pdp2024" class="container">
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
        <template v-else>
          See If You Qualify for Medicare Part D Coverage &
          <span class="text-red"> Save Money on Prescription Medications </span>
        </template>
      </div>

      <template v-if="isMounted">
        <template v-if="showDefaultExperience">
          <img
            src="../../assets/helpline-seniors-hands-up.png"
            width="1254"
            height="836"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
        <template v-else>
          <img
            src="../../assets/buyback-2024.jpeg"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
      </template>

      <UseForm v-slot="{ values }">
        <Form
          id="pdp2024"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'sorry', if: false },
            { id: 'medicaid' },
            { id: 'us' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "smid": "MULTI-PLAN_EHWEB062301_M",
    "xsmid": "MULTI-PLAN_EHWEB062301_M",
    "robots": "noindex"
  }
}
</route>
