<script setup lang="ts">
const { onSubmit } = inject('form') as any

const state = inject('state') as { medicaid: string }
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Take this quiz - see if you qualify
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Please hold on while I review your answers...
  </div>

  <Checklist
    :items="[
      {
        text: 'Has Medicare Parts A and B',
        icon: forms.go4BenefitsBridgeDoor?.medicareab ? 'check' : 'x',
      },
      {
        text: `Has ${state?.medicaid ?? 'Medicaid'}`,
        icon: forms.go4BenefitsBridgeDoor?.medicaid ? 'check' : 'x',
      },
      {
        text: 'Has Medicaid',
        icon: forms.go4BenefitsBridgeDoor?.aditionalBenefits ? 'check' : 'x',
      },
    ]"
    @done="onSubmit()"
  />
</template>
