<template>
  <div
    class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-6"
  >
    <div class="text-xl sm:text-2xl text-red text-center font-medium">
      Looks Good!
    </div>

    <div class="text-xl sm:text-2xl text-center font-medium">
      Continue w Licensed <span class="whitespace-nowrap">Insurance Agent</span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </div>
</template>
