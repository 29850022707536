<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center text-red font-bold">
    Good News!
  </div>

  <div class="text-2xl sm:text-3xl text-center">
    It looks like there are plans in
    {{ ip.lookup?.stateName || 'your area' }}
    with OTC or flexible spending benefits.
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Check your ZIP code with a licensed insurance agent.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Agents Available Now
  </div>
</template>
