<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Product } from '~/modules/product'
import _ from 'lodash'

interface Step {
  component: any
  name: string
  index?: number
}

const emit = defineEmits<{
  (e: 'stepChange', stepNumber: number, oldStepNumber: number): void
  (e: 'stepSubmit', step: Record<string, any>): void
}>()

const props = defineProps<{
  id: string
  product: Product
  submitHandler?: 'leadgen' | (() => Promise<void>)
  showProgress?: boolean
  steps: Step[]
}>()

const steps = props.steps.map((step, index) => {
  return {
    ...step,
    index,
  }
})

const currentStep = ref(0)
const transitionDirection = ref<'forward' | 'backward'>('forward')

const route = useRoute()
const router = useRouter()

const updateHash = (stepName: string) => {
  const queryParams = route.query
  router.push({ hash: `#${stepName}`, query: queryParams })
  analytics.page({
    name: `Form ${props.id} Step ${steps[currentStep.value].name}`,
    category: 'Form',
    product: props.product,
    stepNumber: steps[currentStep.value].index,
  })
}

const setInitialStep = () => {
  const stepNameFromHash = route.hash.replace('#', '')
  const initialStepIndex = steps.findIndex(
    (step) => step.name === stepNameFromHash
  )
  if (initialStepIndex !== -1) {
    currentStep.value = initialStepIndex
  } else if (currentStep.value !== 0) {
    updateHash(steps[currentStep.value].name)
  }
}

onMounted(() => {
  setInitialStep()
  window.addEventListener('popstate', handlePopState)
})

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState)
})

const handlePopState = (e: PopStateEvent) => {
  const stepNameFromHash = window.location.hash.replace('#', '')
  const stepIndex = !!stepNameFromHash
    ? steps.findIndex((step) => step.name === stepNameFromHash)
    : 0

  if (stepIndex !== -1 && stepIndex !== currentStep.value) {
    e.preventDefault()
    const oldStep = currentStep.value
    transitionDirection.value =
      stepIndex < currentStep.value ? 'backward' : 'forward'
    currentStep.value = stepIndex
    emit('stepChange', currentStep.value, oldStep)
  }
}

watch(currentStep, (newStep, oldStep) => {
  if (newStep !== oldStep) {
    updateHash(steps[newStep].name)
    emit('stepChange', newStep, oldStep)
  }
})

const { values, handleSubmit } = useForm()

const onSubmit = handleSubmit(async () => {
  const data = _.omitBy(values, (value, key) => key.startsWith('_'))

  formsRef.value = {
    ...formsRef.value,
    [props.id]: {
      ...formsRef.value[props.id],
      ...data,
    },
  }

  if (typeof data.haveMedicaid === 'boolean') {
    setRingPoolUserData({
      dsnp: data.haveMedicaid ? 1 : 0,
    })
  }

  if (typeof data.haveMedicare === 'boolean') {
    setRingPoolUserData({
      currently_insured: data.haveMedicare ? 1 : 0,
    })
  }
  if (typeof data.zipcode === 'string') {
    setRingPoolUserData({
      zip: data.zipcode,
    })
  }

  emit('stepSubmit', data)
  analytics.track('formStepSubmitted', {
    formVersion: props.id,
    product: props.product,
    stepName: steps[currentStep.value],
    stepNumber: currentStep.value + 1,
    data,
  })
  nextStep()
})

const nextStep = () => {
  const oldStep = currentStep.value
  currentStep.value += 1
  transitionDirection.value = 'forward'
}

provide('form', {
  id: props.id,
})
</script>

<template>
  <form
    @submit="onSubmit"
    id="form"
    :class="[showProgress ? 'show-progress' : '', 'h-auto py-4']"
  >
    <slot v-if="showProgress" name="progress" class="z-10"></slot>
    <transition
      :name="
        transitionDirection === 'forward' ? 'slide-fade' : 'slide-fade-backward'
      "
      mode="out-in"
    >
      <component
        :is="steps[currentStep].component"
        :key="currentStep"
        class="z-0"
      />
    </transition>
    <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
  </form>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-fade-backward-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-backward-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-backward-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-fade-backward-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

form {
  min-height: calc(100vh - 120px);
  display: grid;
  grid-template-rows: 1fr;
}
form.show-progress {
  grid-template-rows: 44px 1fr;
}
</style>
