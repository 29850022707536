<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd && state.top5Otc) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
      top5Otc: `$${state.top5Otc}`,
    }
  }
})
</script>

<template>
  <div class="text-3xl text-green-600 font-semibold"> Congratulations! </div>

  <div class="text-lg font-medium">
    <template v-if="!isMobile || !isPaidUser || !state">
      We were able to find new plans that you may qualify for.
      <span class="bg-yellow-400 whitespace-nowrap">Click below</span> to review
      them with a licensed insurance agent. We're standing by to help!
    </template>
    <template v-else>
      We found plans with
      <span class="bg-yellow-400"
        >{{ state.minOtcLtd }} or more in flexible allowance benefits</span
      >. Use benefits on plan approved items like:
    </template>
  </div>

  <div
    v-if="isMobile && isPaidUser && state"
    class="grid grid-cols-2 max-w-sm mx-auto"
  >
    <div
      v-for="tag in ['Groceries', 'Dental work', 'Prescription', 'Housing']"
      class="flex items-center gap-2"
    >
      <Icon i-heroicons-solid:check class="text-blue" />
      <div> {{ tag }} </div>
    </div>
  </div>

  <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded p-4 space-y-2">
    <div class="text-lg font-semibold text-center">
      Call to confirm eligibility and enroll.
    </div>

    <div class="text-center">
      <span class="font-semibold"> Tap below </span> to speak to a licensed
      insurance agent.
    </div>

    <div class="flex items-center justify-center gap-2">
      <div class="relative flex w-2 h-2">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-2 w-2 bg-green-500">
        </div>
      </div>
      <div> Live agent connected & waiting </div>
    </div>

    <ClickToCall
      :text="phone.formatted"
      :props="{ ctcLocation: 'last-step' }"
      tty
    />

    <div class="text-center">
      Due to high call volume we can only <br />
      reserve your spot for <span class="font-semibold">2 minutes</span>. <br />
      <span class="text-2xl font-medium text-red"> {{ timer }} </span>
    </div>
  </div>
</template>
