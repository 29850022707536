<script setup lang="ts">
import { Ref } from 'vue'

const isProd = import.meta.env.MODE === 'production'
const router = useRouter()

const start = Date.now()
const now = useNow()

const lowBid = inject('lowBid') as Ref<boolean>
const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const top5Otc = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500
  )
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <template v-if="lowBid">
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <div class="text-3xl sm:text-4xl text-center">
      Speak with a licensed insurance agent and get answers to your questions
      about Medicare Advantage plans.
    </div>

    <MediaAlphaAd
      :placementId="
        query.utm_source?.toLowerCase() === 'ma'
          ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
          : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
      "
      :user="{}"
    />
  </template>
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <template v-if="isPaidUser">
      <div class="text-3xl sm:text-4xl text-center">
        Looks like you may qualify for ${{ minOtcLtd }} per year or more to
        spend on groceries!
      </div>
    </template>
    <template v-else>
      <div class="text-3xl sm:text-4xl text-center">
        Finish up with a licensed insurance agent.
      </div>
    </template>

    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

    <div
      v-if="isPaidUser && isProd"
      class="text-lg sm:text-xl font-medium text-center"
    >
      Act quickly: <span class="text-red"> {{ timer }} </span>
    </div>
  </template>
</template>
