<script setup lang="ts">
const router = useRouter()

onMounted(() => {
  const isPaidUser = getSessionEnhanced().isPaidUser

  if (!isPaidUser) {
    router.push('/go-4-benefits')
  }
})

analytics.page({
  name: 'Zero Premium Bridge 1',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="bg-yellow-300 flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
    >
      Ad
    </span>

    <div
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto',
        'gap-6 py-2 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="flex flex-col gap-2 md:flex-grow-0">
        <span
          class="text-4xl text-gray-800 font-bold inline-block font-dm flex gap-2 flex-col md:flex-row md:gap-8 md:justify-evenly md:items-center"
        >
          <span class="flex items-center">Find some</span>
          <span
            class="text-red text-5xl md:text-8xl flex gap-2 md:items-center md:justify-center"
          >
            $0/mo premium
          </span>
          <span class="flex items-center">plan options</span>
        </span>
      </div>
      <div class="image-wrapper">
        <img src="../../assets/trunk-ad.png" class="image" />
      </div>

      <div class="text-lg md:max-w-3xl md:w-full flex my-4 w-full">
        <Button
          to="/additional-benefits-grocery-2024"
          class="font-sans"
          wide
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination: '/additional-benefits-grocery-2024',
            })
          "
        >
          <span class="uppercase"> Learn More </span>
          <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
        </Button>
      </div>
      <ul class="text-base text-black font-thin list-disc pl-5 mt-10">
        <li>
          Shop 2024 Medicare Advantage Plans from carriers like
          UnitedHealthcare®
        </li>
        <li>
          Some plans with additional benefits like Dental, Vision, or Hearing.
        </li>
        <li>
          Get your free no obligation Medicare Advantage plan comparison today!
        </li>
        <li> See if you qualify now! </li>
      </ul>
    </div>
  </Layout>
</template>

<style scoped>
.image-wrapper {
  @apply w-full mx-auto border-4 border-white overflow-hidden md:h-[480px] md:w-[520px] md:border-8;
  border-radius: 48px;
  @media screen and (min-width: 768px) {
    border-radius: 60px;
  }
}
.image {
  transform: scale(1.3) translateY(10px) translateX(-30px);

  @media screen and (min-width: 768px) {
    transform: scale(1.6) translateY(60px) translateX(-90px);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
