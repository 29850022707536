<script setup lang="ts">
useHead({
  title: `Medicare Premium Reduction Listing 2096 - ${brand.name}`,
})

onMounted(() => {
  enterExperiment('medicarePremiumReductionListingImage')
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template #banner>
      <Banner> New to Medicare? Turning 65? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-4 py-6">
      <div class="text-xl sm:text-2xl text-red text-center font-medium">
        Attention: Medicare Part B Premiums increased to
        <span class="whitespace-nowrap">$2096/yr* (+$118)</span>
      </div>

      <img
        v-if="exp?.medicarePremiumReductionListingImage?.card"
        class="w-52 md:w-80 mx-auto"
        src="../../assets/card-medicare-b.png"
        alt="Medicare Card with $174 green stamp"
      />
      <img
        v-else
        src="../../assets/woman-with-check.jpg"
        class="w-52 md:w-80 mx-auto"
        alt="Elderly smiling woman holding a check"
      />

      <div class="text-xl sm:text-2xl text-center font-medium">
        CHECK ELIGIBILITY FOR PLANS WITH A PART B PREMIUM REDUCTION BENEFIT
      </div>

      <Form
        id="medicarePremiumReductionListingR2"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'zipcode' },
          { id: 'medicareab' },
          { id: 'medicaid' },
          { id: 'results' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"> </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB1024268_M",
      "m10": "MULTI-PLAN_HLWEB0624190_M"
    }
  }
}
</route>
