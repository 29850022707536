<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center"> Sorry... </div>

  <div class="text-2xl sm:text-3xl text-center">
    You must have Medicare Parts A & B to enroll in a Medicare
    <span class="whitespace-nowrap">Advantage Plan.</span>
  </div>
</template>
