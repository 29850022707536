<script setup lang="ts">
useHead({
  title: `Additional benefits grocery - ${brand.name}`,
})

const minOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc
})
</script>

<template>
  <Layout header="ping" footer="2025" class="container">
    <div class="max-w-3xl mx-auto space-y-4 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Have Medicare and Medicaid? Some Medicare members in
        {{ ip.lookup?.stateName || 'America' }}
        may be eligible for a Medicare Advantage Special Needs Plan with
        <span class="text-red">
          <span v-if="minOtc"> ${{ minOtc }}/yr or more in </span>
          Medicare Advantage Plan Allowance* to use
          <span class="whitespace-nowrap">towards groceries.</span>
        </span>
      </div>

      <div class="text-gray-700 text-center">
        *Not all plans cover all of these benefit categories. Benefits may vary
        by carrier and location. Limitations and exclusions
        <span class="whitespace-nowrap">may apply.</span>
      </div>

      <img
        src="../../assets/buyback-older-couple.jpg"
        width="400"
        height="267"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <UseForm v-slot="{ values }">
        <Form
          id="grocery"
          class="!max-w-3xl py-4"
          scrollBehavior="auto"
          :steps="[
            { id: 'medicareab' },
            { id: 'sorry', if: values?.haveMedicare === false },
            { id: 'medicaid' },
            { id: 'us' },
            { id: 'call' },
            { id: 'call-now', if: false },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624183_M",
      "m10": "MULTI-PLAN_HLWEB0624181_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0624183_M",
      "m10": "MULTI-PLAN_HLWEB0624181_M"
    }
  }
}
</route>
