<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

useHead({
  title: `New to Medicare? Moving? On Medicaid? - ${brand.name}`,
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = useIsMobile()
const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout
    :hide-header="isMobile"
    header="ping"
    footer="2024"
    class="container flex flex-col"
  >
    <template #banner v-if="!isPaidUser">
      <Banner> New to Medicare? Moving? On Medicaid? </Banner>
    </template>
    <div class="flex-1 py-16">
      <Form
        id="search4Benefits2024"
        :steps="[
          { id: 'benefits' },
          { id: 'medicare' },
          { id: 'medicaid' },
          { id: 'location' },
          { id: 'search' },
        ]"
        product="medicare"
        class="flex flex-col gap-8 !space-y-0"
      />
    </div>

    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "boomer", "med10"],
    "benefits": ["giveback", "otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB082317_M",
      "med10": "MULTI-PLAN_EHWEB082321_M",
      "boomer": "MULTI-PLAN_EHWEB082322_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_EHWEB082317_M",
      "med10": "MULTI-PLAN_EHWEB082321_M",
      "boomer": "MULTI-PLAN_EHWEB082322_M"
    }
  }
}
</route>
