<script setup lang="ts">
useHead({
  title: `My OTC Benefits - ${brand.name}`,
})

const notProd = import.meta.env.MODE !== 'production'

const router = useRouter()
const route = useRoute()

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  // if (!isPaidUser.value && import.meta.env.MODE === 'production') {
  //   router.replace({
  //     path: '/go-4-benefits',
  //     query: route.query,
  //   })
  //   return
  // }
})
</script>

<template>
  <Layout
    hide-header
    footer="2024"
    class="bg-[#f7f7f7] min-h-[100vh] p-6 relative"
  >
    <template v-if="notProd" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>

    <Form
      id="myOtcBenefits2"
      class="!max-w-3xl"
      scrollBehavior="keepPos"
      :steps="[
        { id: 'start' },
        { id: 'medicareab' },
        { id: 'call', completed: true },
      ]"
      product="medicare"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
