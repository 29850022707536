<script setup lang="ts">
import bhBoyImg from '../../assets/bh-boy.png'
import bhGirlImg from '../../assets/bh-girl.png'
import bhHeroImg from '../../assets/bh-hero.png'
import bhMoneyImg from '../../assets/bh-money.png'
import bhPaperImg from '../../assets/bh-paper.png'
import bhTechImg from '../../assets/bh-tech.png'

import aepTimeline from '../../assets/medicare-benefits-aep-timeline.png'
import iepTimeline from '../../assets/medicare-benefits-iep-timeline.png'
import oepTimeline from '../../assets/medicare-benefits-oep-timeline.png'
import sepTimeline from '../../assets/medicare-benefits-sep-timeline.png'

const periods = [
  {
    id: 'oep',
    image: oepTimeline,
  },
  {
    id: 'sep',
    image: sepTimeline,
  },
  {
    id: 'iep',
    image: iepTimeline,
  },
  {
    id: 'aep',
    image: aepTimeline,
  },
]

useHead({
  title: `${brand.name} - Compare Medicare Advantage Plans`,
})

analytics.page({
  name: 'LP 4.0',
  category: 'LP',
  product: 'medicare',
})

const benefits = [
  {
    icon: bhMoneyImg,
    title: 'Compare Medicare Advantage Plans and See if You Can Save!',
    description: 'A dedicated licensed insurance agent will work with you to make sure you find Medicare Advantage Plans in Your Area',
  },
  {
    icon: bhPaperImg,
    title: 'Get the Coverage You Need',
    description: 'A licensed insurance agent will work with you to find a plan that suits your needs',
  },
  {
    icon: bhTechImg,
    title: 'State of the Art Technology',
    description: 'Advanced AI based technology will find you Medicare Advantage plans that fit your budget at your convenience',
  },
  {
    icon: bhBoyImg,
    title: 'Unrivaled Care',
    description: 'A licensed insurance agent will dedicate their time and walk you through plans available in your area until you find the one for you.',
  },
]

const important = [
  {
    icon: bhMoneyImg,
    title: "See if you could find lower monthly payments or additional benefits with a Medicare Advantage plan",
  },
  {
    icon: bhGirlImg,
    title: 'Licensed Insurance Agents will help you find the plan with benefits that may be important to you',
  },
]
</script>

<template>
  <Layout header="ping" footer="go4Benefits" ehealthTracking="MMR-1376-2023">
    <!-- hero -->
    <div
      class="relative bg-gray-400 bg-cover [background-position:right_-220px_center] sm:bg-right-center text-white"
      :style="{ backgroundImage: `url(${bhHeroImg})` }"
    >
      <div
        class="absolute inset-0 bg-gradient-to-b from-gray-800/90 to-gray-800/70"
      >
      </div>
      <div class="relative container md:max-w-2xl py-16 space-y-6">
        <h1 class="text-3xl font-bold text-center">
          Explore Medicare Advantage Plans Options in Your Area
        </h1>
        <div class="text-lg text-center">
          Some Plans May Offer Additional Coverage
        </div>
        <div class="flex justify-center">
          <ClickToCall v-slot="{ handleClick }">
            <Button variant="red" :to="phone.uri" @click="handleClick">
              <Icon i-heroicons-solid:phone class="w-8 h-8 mr-6" />
              <span class="block flex-1 text-left">
                <span class="block text-lg font-bold mb-0.5">
                  {{ phone.formatted }}
                  <span class="whitespace-nowrap">TTY 711, 24/7</span>
                </span>
                <span
                  class="block text-lg leading-snug normal-case font-normal tracking-normal"
                >
                  Talk to a Licensed
                  <span class="whitespace-nowrap">Insurance Agent</span>
                </span>
              </span>
            </Button>
          </ClickToCall>
        </div>
      </div>
    </div>

    <!-- benefits -->
    <div>
      <div class="container py-16">
        <h2 class="text-2xl font-bold text-center">
          Call to Learn About Medicare Advantage Plans that may include
          Additional Plan Benefits
        </h2>
        <div class="flex justify-center mt-6 mb-16">
          <ClickToCall v-slot="{ handleClick }">
            <Button variant="blue" :to="phone.uri" @click="handleClick">
              <Icon i-heroicons-solid:phone class="w-8 h-8 mr-6" />
              <span class="block flex-1 text-left">
                <span class="block text-lg font-bold mb-0.5">
                  {{ phone.formatted }}
                  <span class="whitespace-nowrap">TTY 711, 24/7</span>
                </span>
                <span
                  class="block text-lg leading-snug normal-case font-normal tracking-normal"
                >
                  Talk to a Licensed
                  <span class="whitespace-nowrap">Insurance Agent</span>
                </span>
              </span>
            </Button>
          </ClickToCall>
        </div>
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div v-for="benefit in benefits" :key="benefit.title">
            <img
              :src="benefit.icon"
              class="w-20 h-20 object-contain bg-white rounded-md shadow-sm p-2"
            />
            <div class="mt-4 mb-1 text-xl font-medium">
              {{ benefit.title }}
            </div>
            <div class="text-gray-500"> {{ benefit.description }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- cta -->
    <div class="bg-[#0798da] text-white">
      <div class="container py-16 space-y-6">
        <h2 class="text-2xl font-bold text-center">
          Your Licensed Insurance Agent is Just a Phone Call Away
        </h2>
        <div class="flex justify-center">
          <ClickToCall v-slot="{ handleClick }">
            <Button
              class="!bg-white !text-[#0798da]"
              :to="phone.uri"
              @click="handleClick"
            >
              <Icon i-heroicons-solid:phone class="w-8 h-8 mr-6" />
              <span class="block flex-1 text-left">
                <span class="block text-lg font-bold mb-0.5">
                  {{ phone.formatted }}
                  <span class="whitespace-nowrap">TTY 711, 24/7</span>
                </span>
                <span
                  class="block text-lg leading-snug normal-case font-normal tracking-normal"
                >
                  Talk to a Licensed
                  <span class="whitespace-nowrap">Insurance Agent</span>
                </span>
              </span>
            </Button>
          </ClickToCall>
        </div>
      </div>
    </div>

    <!-- important -->
    <div>
      <div class="container py-16 space-y-16">
        <h2 class="text-2xl font-bold text-center">
          Why it's important to Review Medicare Advantage Plans
        </h2>
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div
            v-for="item in important"
            :key="item.title"
            class="lg:first:col-start-2"
          >
            <img
              :src="item.icon"
              class="w-20 h-20 object-contain bg-white rounded-md shadow-sm p-2"
            />
            <div class="mt-4 text-xl font-medium"> {{ item.title }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>

    <!-- enrollment periods -->
    <div>
      <div class="container py-16">
        <div class="text-2xl font-medium text-center">
          Important Enrollment Periods
        </div>

        <div class="mt-8 space-y-8 max-w-xl mx-auto">
          <div
            v-for="period in periods"
            :key="period.id"
            class="bg-white rounded-md shadow py-6 px-4"
          >
            <img :src="period.image" />
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "smid": "MULTI-PLAN_EHWEB0723032025_C"
  }
}
</route>

