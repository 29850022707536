<script setup lang="ts">
const { value: liveInUs } = useField('liveInUs')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center">
    Do you live in {{ ip.lookup?.stateName || 'America' }}?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="liveInUs = null"
    >
      Skip
    </Button>
  </div>
</template>
