<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Call ${brand.name} for Medicare quotes`,
})

const date = ref()
const isPaidUser = ref(true)

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Turning 65? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        Shop 2025 {{ ip.lookup?.stateName || '' }} Medicare Advantage Plans
      </div>

      <div class="text-lg sm:text-2xl text-center">
        Find Medicare Advantage Plans with OTC or Flexible Spending Allowance
        benefits near you with carriers like Humana and UnitedHealthcare®
      </div>

      <div class="text-sm">
        Not all plans offer these benefits. All of these benefits will not
        always be offered on a single Plan.
      </div>

      <div class="w-[90%] sm:w-[400px] mx-auto relative">
        <img src="../../assets/helpline-seniors-hands-up.png" class="w-full" />
        <StateMap
          v-if="ip.lookup?.stateCode"
          :state-code="ip.lookup.stateCode"
          class="absolute right-2 bottom-2 w-1/3 text-red"
        />
      </div>

      <Form
        id="click4OtcR2"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'medicare' },
          { id: 'us' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call' },
        ]"
        product="medicare"
      />

      <div class="text-2xl text-center font-semibold">
        Medicare Advantage and Part D plans and benefits offered by companies
        like Humana and UnitedHealthcare®
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "benefits": ["otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624193_M",
      "m10": "MULTI-PLAN_HLWEB0924251_M"
    }
  }
}
</route>
