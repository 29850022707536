<script setup lang="ts">
const { value: wantDsnp } = useField('wantDsnp')

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl sm:text-2xl text-center font-medium">
    Do you want to find a Medicare Advantage D-SNP plan with ${{ minOtcLtd }}
    per year or more in Allowances to use towards groceries?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="wantDsnp = true"> Yes </Button>
    <Button wide size="sm" type="submit" @click="wantDsnp = false"> No </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantDsnp = null"
    >
      Skip
    </Button>
  </div>
</template>
