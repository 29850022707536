<script setup lang="ts">
const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})
</script>

<template>
  <div class="text-3xl text-green-600 font-semibold"> Congratulations! </div>

  <div class="text-xl sm:text-2xl">
    Looks like there may be Medicare Advantage plans with Allowance benefits in
    your area. Some plans include
    <span class="text-red"> {{ state?.minOtcLtd }} or more </span>to use towards
    groceries.
  </div>
  <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded p-4 space-y-2">
    <div class="text-lg font-semibold text-center">
      Call the {{ brand.name }} now to speak to a licensed insurance agent
    </div>

    <ClickToCall
      :props="{ ctcLocation: 'grocery-allowance-2-last-step' }"
      tty
    />

    <div class="text-center text-gray-500"> Available M-F 9-7 EST </div>

    <div class="flex items-center justify-center space-x-4">
      <div class="relative flex w-4 h-4">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div
          class="relative inline-flex rounded-full h-4 w-4 bg-green-500"
        ></div>
      </div>
      <div class="text-lg sm:text-xl font-medium">
        Licensed sales agents <br />
        are standing by now
      </div>
    </div>
  </div>
</template>
