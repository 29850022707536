<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>([])
const loading = ref(true)
const error = ref(false)

onMounted(async () => {
  try {
    plans.value = await getPlans({
      zipCode: forms.shop4DsnpOtcR2?.zipcode,
      carrier_groups: [],
      amount: 100,
      starting_from: 0,
    })
  } catch {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <Spinner v-if="loading" class="mx-auto" />
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-xl text-red text-center font-medium">
      <template v-if="error || plans.length === 0">
        We're having trouble finding this benefit near you. A licensed insurance
        agent may be able to help. Click to call below.
      </template>
      <template v-else>
        Good news! It looks like there are plans in
        {{ ip.lookup?.stateName || 'America' }} with OTC or flexible spending
        benefits. Check your zip code with a licensed insurance agent.
      </template>
    </div>

    <div class="text-xl text-center font-medium">
      Click to Compare Plans with a Licensed
      <span class="whitespace-nowrap">Insurance Agent</span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Call {{ phone.formatted }} <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>

    <div class="text-center text-lg font-medium"> Agents available now </div>
  </template>
</template>
