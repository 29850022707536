<script setup lang="ts">
useHead({
  title: `Flex Card - ${brand.name}`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.top5Otc !== null) {
    return {
      code: state.code,
      name: state.name,
      top5Otc: `$${state.top5Otc}`,
    }
  }
})

const currStep = ref<string>()
</script>

<template>
  <Layout header="blue" footer="2024" class="container min-h-screen">
    <div class="max-w-3xl mx-auto space-y-4 py-4">
      <template v-if="currStep !== 'loading' && currStep !== 'call'">
        <div class="text-xl sm:text-3xl font-bold">
          <template v-if="!isMobile || !isPaidUser || !state">
            Medicare Members are rushing to sign up for
            <span class="text-blue-600 whitespace-nowrap">
              new 2024 plans.
            </span>
            Here's how to get yours.
          </template>
          <template v-else>
            People with Medicare could be getting more than
            <span class="text-blue-600 whitespace-nowrap">
              {{ state.top5Otc }}/yr+
            </span>
            in OTC benefits with these 5 {{ state.name }} Medicare Advantage
            plans
          </template>
        </div>

        <div class="flex items-center gap-2 border-t border-gray-300 pt-2">
          <img src="../../assets/emily-avatar.png" class="w-10 h-10" />
          <div>
            <div class="text-sm font-semibold text-gray-900"> By Emily </div>
            <div class="text-sm text-gray-500"> Senior Today </div>
          </div>
          <div class="ml-auto flex items-center gap-1">
            <div
              class="py-1 px-2 rounded-sm bg-[#19a2f3] text-white text-xs flex items-center gap-1"
            >
              <Icon i-simple-icons:twitter class="w-3 h-3" />
              <div> 33K </div>
            </div>
            <div
              class="py-1 px-2 rounded-sm bg-[#3b5999] text-white text-xs flex items-center gap-1"
            >
              <Icon i-simple-icons:facebook class="w-3 h-3" />
              <div> 2.6K </div>
            </div>
            <div
              class="py-1 px-2 rounded-sm bg-[#334cba] text-white text-xs flex items-center gap-1"
            >
              <Icon i-fa6-solid:share-from-square class="w-3 h-3" />
              <div> 8.2K </div>
            </div>
          </div>
        </div>

        <img
          src="../../assets/allowance-card.png"
          width="720"
          height="270"
          class="sm:w-[400px] mx-auto"
        />

        <div v-if="!isMobile || !isPaidUser || !state">
          Many seniors are not aware of new Medicare Advantage plans featuring a
          range of added benefits.
          <span class="font-semibold underline">Now is a great time</span> to
          talk to a licensed insurance agent and discover options available near
          you.
        </div>
        <div v-else>
          5 Medicare Advantage Plans in {{ state.name }} include spending
          allowances of {{ state.top5Otc }}/yr or more for plan approved items
          at stores like {{ 'Walmart, CVS, Walgreens, and Target' }}.
        </div>
      </template>

      <div
        :class="[
          currStep !== 'loading' &&
            currStep !== 'call' &&
            '-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 relative',
        ]"
      >
        <Form
          id="click4OtcPaid"
          :class="[
            currStep !== 'loading' && currStep !== 'call' && 'p-4 sm:p-6',
            '!space-y-4 !max-w-3xl',
          ]"
          scrollBehavior="auto"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
          @step-change="currStep = $event.id"
        />
      </div>

      <template v-if="currStep !== 'loading' && currStep !== 'call'">
        <div
          class="flex items-center justify-center gap-4 bg-gray-200 border border-gray-300 p-4"
        >
          <Icon
            i-heroicons-outline:exclamation
            class="w-6 h-6 text-gray-700 shrink-0"
          />
          <div>
            Compare Medicare Advantage Plans and Benefits from Humana, Aetna,
            and UnitedHealthcare® with additional benefits.
          </div>
        </div>

        <div class="flex flex-wrap justify-center gap-2">
          <div
            v-for="tag in ['Fact checked', 'Expert reviewed']"
            :key="tag"
            class="border border-gray-300 rounded-full py-1 px-3 flex items-center gap-1 whitespace-nowrap uppercase text-sm font-medium"
          >
            <div> {{ tag }} </div>
            <Icon i-heroicons-solid:check class="text-blue-600" />
          </div>
        </div>
      </template>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "benefits": ["otc"],
    "smid": {
      "bh": "PLAN_EHWEB082329_M"
    },
    "robots": "noindex"
  }
}
</route>
