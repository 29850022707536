<template>
  <div class="text-2xl text-blue text-center font-medium">
    Search for plans
  </div>

  <div class="text-xl text-center font-medium">
    Where do you live?
  </div>

  <Field
    type="zip"
    name="zipcode"
    rules="required"
    placeholder="Enter ZIP Code"
    class="text-center"
    :initialValue="query.zip"
  />

  <FormButtonNext> Continue </FormButtonNext>
</template>
