<script setup lang="ts">
const { value: gender } = useField('gender')
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center"> What is your gender? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="gender = 'male'"
    >
      Male
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="gender = 'female'"
    >
      Female
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="gender = null"
    >
      Prefer not to say
    </Button>
  </div>
</template>
