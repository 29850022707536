<script setup lang="ts">
defineProps<{
  buttonLabel?: string
  disabled?: boolean
}>()
const emit = defineEmits<{
  (e: 'submitStateCode', value: any): void
}>()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  emit('submitStateCode', values.state)
})
</script>

<template>
  <div class="flex w-full">
    <Select
      name="state"
      initialValue="AL"
      :options="
        states.map((state) => ({ value: state.code, text: state.name }))
      "
      class="flex-grow"
      css-class="rounded-none rounded-l-lg h-16 flex-grow"
    />
    <button
      @click="onSubmit"
      :disabled="disabled"
      class="bg-red hover:bg-red-700 text-white font-bold h-16! py-4 px-4 rounded-r-lg border-2 border-red h-full disabled:bg-gray-300 disabled:border-gray-300"
    >
      {{ buttonLabel ?? 'Start' }}
    </button>
  </div>
</template>

<style scoped>
.statecode-input-wrapper {
  @apply items-center grid font-normal;

  grid-template-columns: 0px 12rem auto;

  > input[type='number'] {
    outline: 0;
    outline-offset: 0;
  }
}
</style>
