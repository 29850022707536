<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center"> Where do you live? </div>

  <Field
    type="zip"
    name="zipcode"
    placeholder="Enter ZIP Code (optional)"
    class="text-center"
  />

  <FormButtonNext> Continue </FormButtonNext>
</template>
