<script setup lang="ts">
const { onSubmit } = inject('form') as any
</script>

<template>
  <div class="text-3xl sm:text-4xl text-center">
    Please wait while I review your answers...
  </div>

  <Checklist
    :items="[
      {
        text: 'Lives in US',
        icon: forms.click2LowerPremiums?.liveInUs ? 'check' : 'x',
      },
      {
        text: 'Has Medicare',
        icon: forms.click2LowerPremiums?.haveMedicare ? 'check' : 'x',
      },
      {
        text: 'Has Medicaid',
        icon: forms.click2LowerPremiums?.haveMedicaid ? 'check' : 'x',
      },
    ]"
    @done="onSubmit()"
  />
</template>
