<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

useHead({
  title: `Reducción de la Prima de Medicare 2096 - ${brand.name}`,
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    1500
  )
})

enterExperiment('shop4DsnpOTC2024Headline')
enterExperiment('shop4DsnpOTC2024Description')

const state = computed(() => ip.lookup?.stateName ?? 'Estados Unidos')

const plans = ref<PlanRowView[]>(Array(30))

onMounted(async () => {
  if (exp.value?.shop4DsnpOTC2024Description?.countDsnpPlans) {
    try {
      await until(() => ip.lookup).toBeTruthy()

      const zipCode = ip.lookup?.postalCode
      if (zipCode) {
        plans.value = await getPlans({
          zipCode: zipCode,
          amount: 1000,
          starting_from: 0,
        })
      }
    } catch (e) {
      console.error('No se pudieron cargar los planes', { e })
    }
  }
})

const headline = computed(() => {
  const headline =
    getCurentValidExperiment(exp.value?.shop4DsnpOTC2024Headline) ?? 'compare'
  switch (headline) {
    case 'medicareAdvantageDsnp':
      return `Planes Medicare Advantage D-SNP 2024 con Asignación de Gastos Flexibles`
    case 'medicareVariableMinAmount':
      return `Planes Medicare Advantage D-SNP con ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/año o más en Asignación para Comestibles`
    case 'variableMinAmountGroceryAllowance':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/año o más en Asignación para Comestibles con estos Planes Medicare Advantage D-SNP`
    case 'affordableMedicare':
      return `Planes Medicare Advantage D-SNP Asequibles con Beneficios de Asignación para Alimentos`
    default:
      return `Compare Planes Medicare Advantage de Necesidades Especiales 2024`
  }
})

const description = computed(() => {
  const description =
    getCurentValidExperiment(exp.value?.shop4DsnpOTC2024Description) ??
    'shopMedicareCommon'
  switch (description) {
    case 'shopMedicareApproved':
      return `Compre planes Medicare Advantage que incluyen una asignación de gastos flexibles para artículos aprobados que puede usar en muchos minoristas importantes`
    case 'variableMinAmount':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/año o más en beneficios de asignación OTC`
    case 'flexibleAlloance':
      return `Beneficio de Asignación Flexible: Obtenga ${USDollarFormatter(
        0
      ).format(
        minOtcLtd.value
      )}/año o más en una tarjeta prepaga para usar en artículos aprobados`
    case 'prePaidCard':
      return `Tarjeta prepaga incluida con algunos planes Medicare Advantage para artículos aprobados.`
    case 'lookingForFlexible':
      return `¿Busca un Beneficio de Asignación de Gastos Flexibles OTC? Podemos ayudar.`
    case 'variableMinAmountFlexible':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )} o más en beneficios de gastos flexibles para artículos aprobados`
    case 'variableMinAmountFood':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )} o más en Asignación para Alimentos`
    case 'youMayBeElegible':
      return `Puede ser elegible para ${USDollarFormatter(0).format(
        minOtcLtd.value
      )} o más en Asignación para Comestibles`
    case 'countDsnpPlans':
      return `${plans.value.length} planes D-SNP en ${
        state.value
      } incluyen asignaciones de gastos de ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/por año o más para artículos aprobados en muchos minoristas importantes`
    case 'somePlansProvide':
      return `Algunos planes proporcionan una tarjeta prepaga para usar en artículos aprobados en línea y en muchos minoristas importantes`
    default:
      return `Compre planes Medicare Advantage que incluyen una asignación de gastos flexibles para artículos OTC comunes.`
  }
})
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> ¿Nuevo en Medicare? ¿En Medicaid? ¿Se muda? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="space-y-2">
        <div class="text-xl text-red text-center font-medium">
          {{ headline }}
        </div>
      </div>

      <img
        src="../../assets/allowance-card-blank.png"
        class="w-full sm:w-auto sm:h-64 mx-auto"
        alt="Ilustración de tarjeta de asignación"
      />
      <div class="text-xl text-red text-center font-medium">
        {{ description }}
      </div>

      <div class="text-xl text-blue text-center">
        Compre planes de aseguradoras como <br />
        <span class="font-medium"> HUMANA, UNITEDHEALTHCARE® </span>
      </div>

      <div class="border-b border-gray-500 border-dashed"></div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="shop4DsnpOtc2024es"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'zipcode' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "benefits": ["food", "groceries", "otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0224144_M",
      "m10": "MULTI-PLAN_HLWEB0224150_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0224144_M",
      "m10": "MULTI-PLAN_HLWEB0224150_M"
    }
  }
}
</route>
