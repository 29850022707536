<script setup lang="ts">
const notProd = import.meta.env.MODE !== 'production'

const card = ref(null)

const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 15 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})
</script>

<template>
  <span class="absolute text-xs top-2 right-2 border border-black p-1 rounded">
    Ad
  </span>

  <div class="space-y-6">
    <div class="text-4xl text-gray-800 font-bold font-dm text-center">
      Hey, there! Did you know that you might qualify for flexible OTC allowance
      in some Advantage Medicare plans?
    </div>

    <div ref="card" class="aspect-[380/253] max-w-[380px] relative mx-auto">
      <img src="../../assets/allowance-card-blank.png" class="w-full h-full" />
      <div
        class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
        :style="{
          fontSize: `${height * 0.1}px`,
          lineHeight: `${height * 0.15}px`,
        }"
      >
        <template v-if="name">
          <Typewriter :text="name" class="truncate" cursor="" :speed="75" />
        </template>
        <div v-else class="truncate"> Emily Taylor </div>
      </div>
    </div>

    <div class="text-gray-600 text-center font-bold">
      <div v-if="notProd" class="text-2xl"> Click below and get started! </div>
      <div v-else class="text-sm"> with some Medicare Advantage Plans </div>
    </div>

    <FormButtonNext
      class="!bg-gradient-to-b !from-[#00b2ff] !to-[#006aff] !font-sans !text-size-[1.375rem] !leading-7 !font-semibold !uppercase !tracking-wider"
    >
      Learn More
    </FormButtonNext>
  </div>
</template>
