<script setup lang="ts">
const head = {
  title: `Find Medicare quotes near you with ${brand.name}`,
}

const page = {
  name: 'Dynamic State LP',
  category: 'LP',
  product: 'medicare',
}
</script>

<template>
  <LandingPageMedigap :head="head" :page="page">
    <template #title>
      New 2023 Medicare Insurance Plans
      <template v-if="!ip.lookup?.stateCode"> in… </template>
      <template v-else>
        in <Underline> {{ ip.lookup.stateCode }} </Underline>
      </template>
    </template>
    <template #subtitle>
      Check your zipcode for plans with extra benefits like dental, vision, and
      prescription coverage.
    </template>
  </LandingPageMedigap>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "bh"],
    "smid": {
      "m10": "MULTI-PLAN_EHWEB092201_M",
      "default": "MULTIPLAN_EHWEB0819v1_M"
    }
  }
}
</route>
