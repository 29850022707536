<script setup lang="ts">
useHead({
  title: `New to Medicare? Moving? On Medicaid? - ${brand.name}`,
})

const showFooterPhone = ref(true)

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="ping" footer="2024" class="container flex flex-col">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="flex-1 py-16">
      <UseForm v-slot="{ values }">
        <Form
          id="search4Otc2024"
          :steps="[
            { id: 'start' },
            { id: 'end', if: !values?.showZip },
            { id: 'search' },
            { id: 'benefits' },
          ]"
          product="medicare"
        />
      </UseForm>
      <FooterPhone2 v-if="showFooterPhone" @close="showFooterPhone = false" />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB082302_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_EHWEB082302_M"
    }
  }
}
</route>
