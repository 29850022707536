<script setup lang="ts">
import newsYahooFinance from '../../assets/mb/news-yahoo-finance.svg'
import newsBusinessInsider from '../../assets/mb/news-business-insider.svg'
import newsForbes from '../../assets/mb/news-forbes.svg'
import newsFox from '../../assets/mb/news-fox.svg'
import newsNbc from '../../assets/mb/news-nbc.svg'
import logoCignaGray from '../../assets/carrier-cigna-gray.svg'
import logoHumanaGray from '../../assets/carrier-humana-gray.svg'
import logoBlueCrossGray from '../../assets/carrier-blue-cross-gray.svg'
import logoAnthemGray from '../../assets/carrier-anthem-gray.svg'
import logoAetnaGray from '../../assets/carrier-aetna-gray.svg'
import logoUnitedGray from '../../assets/carrier-united-gray.svg'

useHead({
  title: `Medicare Advantage D-SNP Plans with Grocery Allowance - ${brand.name}`,
  meta: [
    {
      name: 'description',
      content: `Find Medicare Advantage D-SNP Plans in your state with grocery allowance benefits. Compare plans and learn if you are eligible.`,
    },
  ],
})

const router = useRouter()
const route = useRoute()
const isProd = import.meta.env.MODE === 'production'
const isPaidUser = ref(true)
const loading = ref<boolean>(true)
onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  loading.value = false
})

analytics.page({
  name: 'Shop 4 DSNP OTC',
  category: 'LP',
  product: 'medicare',
})

const destination = computed(() => {
  return '/medicare-medicaid-quiz-8td'
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    1500
  )
})

provide('minOtcLtd', minOtcLtd)

const card = ref(null)
const { height } = useElementSize(card)

const logos = [
  {
    id: 'yahooFinance',
    image: newsYahooFinance,
    name: 'Yahoo',
  },
  {
    id: 'businessInsider',
    image: newsBusinessInsider,
    name: 'Business Insider',
  },
  {
    id: 'forbes',
    image: newsForbes,
    name: 'Forbes',
  },
  {
    id: 'fox',
    image: newsFox,
    name: 'Fox News',
  },
  {
    id: 'nbc',
    image: newsNbc,
    name: 'NBC',
  },
]

const carriers = [
  {
    id: 'cigna',
    image: logoCignaGray,
  },
  {
    id: 'humana',
    image: logoHumanaGray,
  },
  {
    id: 'blueCrossBlueShield',
    image: logoBlueCrossGray,
  },
  {
    id: 'anthem',
    image: logoAnthemGray,
  },
  {
    id: 'aetna',
    image: logoAetnaGray,
  },
  {
    id: 'unitedHealthcare',
    image: logoUnitedGray,
  },
]

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  return {
    code: state?.code ?? '',
    minOtcLtd: state?.minOtcLtd ?? 1500,
  }
})
const routerClick = () => {
  analytics.track('learnMoreClicked', {
    source: route.fullPath,
    destination: destination,
  })
}

const redirect = (zipCode: any) => {
  analytics.track('learnMoreClicked', {
    source: route.fullPath,
    destination: destination.value,
  })
  router.push(`${destination.value}?zipCode=${zipCode}`)

  return
}

const isMobile = useIsMobile()
</script>

<template>
  <Layout
    header="blue"
    footer="2024"
    :class="
      ['bg-[#f7f7f7]', 'min-h-[100vh] gap-6 md:gap-12 font-dm relative'].join(
        ' '
      )
    "
  >
    <template #banner v-if="!isMobile">
      <Banner>New to Medicare? Have Medicaid?</Banner>
    </template>
    <span
      v-if="loading"
      class="inset-0 flex items-center justify-center font-dm"
    >
      <Spinner />
    </span>
    <div
      v-if="!loading"
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="px-6 space-y-6 py-4 md:items-center md:flex md:flex-col">
        <div class="md:flex-grow-0">
          <span class="text-3xl text-gray-800 font-bold font-dm">
            Compare {{ state.code }} Medicare Advantage D-SNP Plans with
            <span class="text-red text-4xl">${{ state.minOtcLtd }}/yr </span>
            or more grocery allowance
          </span>
        </div>
        <div ref="card" class="aspect-[380/253] max-w-[380px] relative">
          <img
            src="../../assets/allowance-card-blank.png"
            class="w-full h-full"
            alt="Medicare allowance card"
          />
          <div
            class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
            :style="{
              fontSize: `${height * 0.1}px`,
              lineHeight: `${height * 0.15}px`,
            }"
          >
            <div class="truncate">Emily Taylor</div>
          </div>
        </div>

        <ZipCode @submit-zip-code="redirect" />
        <p
          class="text-xs text-gray-500 mt-2 font-normal flex items-center justify-center w-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 8 8"
            class="h-4 w-4 mr-1"
          >
            <path
              fill="currentColor"
              d="m4 0l-.19.09l-3.5 1.47l-.31.13V2c0 1.66.67 3.12 1.47 4.19c.4.53.83.97 1.25 1.28c.42.31.83.53 1.28.53c.46 0 .86-.22 1.28-.53c.42-.31.85-.75 1.25-1.28C7.33 5.12 8 3.66 8 2v-.31l-.31-.13L4.19.09zm0 1.09V7c-.04 0-.33-.07-.66-.31s-.71-.63-1.06-1.09a6.26 6.26 0 0 1-1.22-3.28L4 1.1z"
            />
          </svg>

          No junk mail. No spam calls. Free quotes.
        </p>
      </div>
    </div>
    <div class="bg-gray-100 w-full">
      <div class="py-8">
        <div class="text-center text-gray-500">
          <template v-if="isPaidUser && isProd">
            Shop Popular Carriers Like:
          </template>
          <template v-else> As seen on: </template>
        </div>
        <div class="flex flex-wrap gap-4 justify-center gap-2 mt-6">
          <template v-if="isPaidUser && isProd">
            <img
              v-for="carrier in carriers"
              :key="carrier.id"
              :src="carrier.image"
              class="w-24 invert-50"
              :alt="`${carrier.id} logo`"
            />
          </template>
          <template v-else>
            <img
              :alt="`${logo.name} logo`"
              v-for="logo in logos"
              :key="logo.id"
              :src="logo.image"
              class="w-18 h-6 invert-50"
            />
          </template>
        </div>
      </div>
    </div>
    <div
      :class="[
        'container h-full flex-grow flex mx-auto',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div
        class="text-gray-600 text-base font-sans text-center bg-yellow-200 p-4 rounded-md shadow-md"
      >
        22 Plans in {{ ip.lookup?.stateName || 'America' }} include benefits
        like spending allowances of
        <span class="text-red-600 font-bold">${{ minOtcLtd }} </span>
        or more for approved items
      </div>
    </div>
    <!-- logo cloud -->

    <StickyClickToCall />
    <div class="container px-6 py-8 space-y-16 max-w-xl">
      <div class="grid gap-6">
        <img
          src="../../assets/ac-home-img-card-experts.png"
          width="1780"
          height="1748"
          class="max-w-64 mx-auto"
          alt="Smiling mature man holding an allowance card"
        />
        <div>
          <div class="text-2xl font-medium">
            Affordable Medicare Advantage D-SNP Plans with Food Allowance
            Benefits
          </div>
          <ul class="list-disc list-inside mt-2 mb-6 text-gray-600">
            <li>
              You May Be Eligible for ${{ minOtcLtd }} or More in Grocery
              Allowance benefits
            </li>
            <li>
              Some plans provide a pre-paid card benefit to use on approved
              items online and at many major retailers.
            </li>
          </ul>
          <RouterLink
            to="/medicare-medicaid-quiz-8td"
            class="cta-small-button"
            @click="routerClick()"
          >
            Learn About Grocery Allowance
            <span class="inline-block text-blue-600 ml-1">→</span>
          </RouterLink>
        </div>
      </div>
      <div class="grid gap-6">
        <img
          src="../../assets/ac-home-img-personal-recommendations.png"
          width="1024"
          height="1024"
          class="max-w-64 mx-auto"
          alt="Smiling mature man holding an allowance card"
        />
        <div>
          <div class="text-2xl font-medium">
            Compare 2024 Medicare Advantage Special Needs Plans
          </div>
          <ul class="list-disc list-inside mt-2 mb-6 text-gray-600">
            <li>
              Shop Medicare Advantage plans that include benefits like a
              flexible spending allowance for approved items you can use at many
              major retailers
            </li>
          </ul>
          <RouterLink
            to="/medicare-medicaid-quiz-8td"
            class="cta-small-button"
            @click="routerClick()"
          >
            Shop Medicare Advantage Plans
            <span class="inline-block text-blue-600 ml-1">→</span>
          </RouterLink>
        </div>
      </div>
      <div class="grid gap-6">
        <img
          src="../../assets/ac-home-img-tool.png"
          width="1024"
          height="1024"
          class="max-w-64 mx-auto"
          alt="Smiling mature woman holding an allowance card"
        />
        <div>
          <div class="text-2xl font-medium">
            At least ${{ minOtcLtd }}/yr in Food Allowance benefits
          </div>
          <ul class="list-disc list-inside mt-2 mb-6 text-gray-600">
            <li>
              Shop plans from carriers like Humana, UnitedHealthcare®, Anthem,
              Blue Cross Blue Shield, Aetna, Cigna Healthcare, Wellcare, Kaiser
              Permanente
            </li>
          </ul>
          <RouterLink
            to="/medicare-medicaid-quiz-8td"
            class="cta-small-button"
            @click="routerClick()"
          >
            Allowance Card Eligibility Quiz
            <span class="inline-block text-blue-600 ml-1">→</span>
          </RouterLink>
        </div>
      </div>
    </div>
    <div class="px-6 container py-8 max-w-xl">
      <div class="bg-purple-100 rounded-2xl p-6 space-y-4">
        <div class="flex justify-center">
          <div class="bg-purple-200 p-2 rounded-full">
            <Icon
              i-heroicons-outline:shield-check
              class="w-12 h-12 text-purple-800"
            />
          </div>
        </div>
        <div>
          <div class="text-2xl mb-2 font-medium text-center">
            We care about privacy
          </div>
          <div class="text-center">
            Shop for plans anonymously. Use our secure tools with confidence.
            We'll never call you or sell your information. We only ask questions
            that help us serve you.
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 w-full py-8">
      <div class="container max-w-xl mx-auto">
        <div class="text-center text-gray-500">As seen on:</div>
        <div class="flex flex-wrap justify-center gap-4 mt-6">
          <img
            v-for="logo in logos"
            :key="logo.id"
            :src="logo.image"
            class="w-18 h-6 invert-50"
            :alt="`${logo.name}`"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.list-disc {
  list-style-type: disc;
}
.list-inside {
  padding-left: 1rem;
}
.max-w-64 {
  max-width: 16rem;
}

/* Additional styles for better bullet point presentation */
.list-disc > li {
  margin-bottom: 0.5rem; /* Adjust spacing between bullet points */
  line-height: 1.5; /* Improve readability */
}

.list-disc > li::marker {
  font-weight: bold; /* Optional: Make bullet points bolder */
}
.cta-small-button {
  @apply w-full font-medium rounded-full flex justify-between border border-blue-200 bg-blue-100 px-4 py-2;
}
.input-wrapper {
  @apply text-lg md:max-w-3xl md:max-w-lg my-4 w-full relative grid;
  grid-template-columns: 12rem 1fr;
}

@media screen and (min-width: 768px) {
  .input-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["m10", "bh"],
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0224150_M",
      "bh": "MULTI-PLAN_HLWEB0224144_M"
    },
    "xsmid": {
      "m10": "MULTI-PLAN_HLWEB0224150_M",
      "bh": "MULTI-PLAN_HLWEB0224144_M"
    },
    "robots": "noindex"
  }
}
</route>
