<template>
  <div class="text-xl text-red text-center font-medium"> Looks Good! </div>

  <div class="text-xl text-center font-medium">
    Your licensed insurance
    <span class="whitespace-nowrap">agent is ready to help.</span>
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }">
    <template #text>
      Click to Call
      <br />
      TTY 711, 24/7
    </template>
  </ClickToCall>
</template>
