<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div class="text-xl text-red text-center font-medium">
    Verificar elegibilidad
  </div>

  <div class="text-xl text-center font-medium"> ¿Tiene Medicare? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="haveMedicare = true">
      Sí
    </Button>
    <Button wide size="sm" type="submit" @click="haveMedicare = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Omitir
    </Button>
  </div>
</template>