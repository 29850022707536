<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red text-center"> Take this quiz </div>

  <div class="text-3xl sm:text-4xl text-center">
    Are you currently enrolled in Medicare?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
