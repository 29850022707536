<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center text-red font-bold">
    Good News!
  </div>

  <div class="text-2xl sm:text-3xl text-center">
    We found a licensed insurance agent who can help you check your eligibility
    and compare these plans.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Licensed Insurance Agents available
  </div>
</template>
