<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>
<template>
  <div class="flex flex-col justify-between h-full">
    <div class="container flex gap-4 flex-col pt-2">
      <img
        src="../../../assets/card-medicare.png"
        class="w-52 mx-auto"
        alt="Medicare Card Icon"
      />
      <div class="text-center text-lg font-semibold mb-2">
        Do you have a <span class="text-blue-600">Medicare card </span>?
      </div>
      <div class="text-center text-base text-gray-600">
        Part A covers hospital care. Part B covers medical care.
      </div>

      <div class="flex flex-col gap-2">
        <Button
          wide
          size="sm"
          type="submit"
          variant="undefined"
          class="bg-blue-600 text-white py-2 !rounded-full shadow-md"
          @click="haveMedicare = true"
        >
          Yes
        </Button>
        <Button
          wide
          size="sm"
          type="submit"
          variant="undefined"
          class="border border-blue-600 text-blue-600 py-2 !rounded-full shadow-md"
          @click="haveMedicare = false"
        >
          No
        </Button>
        <Button
          wide
          size="sm"
          type="submit"
          variant="gray"
          class="col-span-2"
          @click="haveMedicare = null"
        >
          Skip
        </Button>
      </div>
    </div>
    <HighlyRatedCompanies />
  </div>
</template>
