<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>
<template>
  <div class="py-8 space-y-6 container">
    <div class="text-xl sm:text-2xl text-center font-medium">
      What is your <span class="text-blue-600">ZIP Code</span>?
    </div>

    <Field
      type="zip"
      name="zipcode"
      placeholder="Enter ZIP Code"
      class="text-center"
    />

    <FormButtonNext> Continue </FormButtonNext>
  </div>
</template>
