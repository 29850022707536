<script setup lang="ts">
import { BenefitView } from '~/modules/plan'
import dental from '~/assets/dental-fill.svg'
import vision from '~/assets/eye-fill.svg'
import card from '~/assets/card.svg'
import coin from '~/assets/coin-filled.svg'
import grocery from '~/assets/grocery.svg'
import home from '~/assets/home.svg'

const props = defineProps<{
  benefit: BenefitView
}>()
const backgroundColor = computed(() => {
  switch (props.benefit.name) {
    case 'Dental':
      return '#e26363'
    case 'Vision':
      return '#414BB2'
    case 'OTC':
      return '#309826'
    default:
      return '#652CB3'
  }
})

const iconImage = computed(() => {
  switch (props.benefit.name) {
    case 'Dental':
      return dental
    case 'Vision':
      return vision
    case 'OTC':
      return card
    case 'Giveback':
      return coin
    case 'Allowance Card':
    case 'Flex Card':
      return card
    default:
      return null
  }
})
</script>

<template>
  <div
    class="flex rounded-lg px-4 py-2 text-white gap-4 shadow-lg"
    :style="{ backgroundColor: backgroundColor }"
  >
    <img
      type="image/svg+xml"
      v-if="iconImage"
      :src="iconImage"
      class="w-6 h-6 text-white"
      :style="{ fill: 'white' }"
    />

    {{ benefit.name }}
  </div>
</template>
