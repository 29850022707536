<script setup lang="ts">
import { Step } from '~/modules/forms'

import file1 from '../../assets/audios/my-otc-benefits-audio/file-1.mp3'
import file2 from '../../assets/audios/my-otc-benefits-audio/file-2.mp3'
import file3 from '../../assets/audios/my-otc-benefits-audio/file-3.mp3'
import file4 from '../../assets/audios/my-otc-benefits-audio/file-4.mp3'
useHead({
  title: `My OTC Benefits - ${brand.name}`,
})
const notProd = import.meta.env.MODE !== 'production'

const router = useRouter()
const route = useRoute()

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.replace({
      path: '/go-4-benefits',
      query: route.query,
    })
    return
  }
})

const audioIndex = ref('start')
const autoPlay = ref(true)
const isMuted = ref(false)
const audioArray: { [k: string]: any } = {
  start: file1,
  medicareab: file2,
  medicaid: file3,
  call: file4,
}

const audioLocalStorage = useLocalStorage<{
  autoPlay: boolean
  isMuted: boolean
}>('audioLocalStorage', {
  autoPlay: true,
  isMuted: false,
})

if (audioLocalStorage.value.autoPlay) {
  autoPlay.value = audioLocalStorage.value.autoPlay
}
if (audioLocalStorage.value.isMuted) {
  isMuted.value = audioLocalStorage.value.isMuted
}

const changeStep = (newStep: Step, oldStep?: Step) => {
  if (newStep.id) {
    audioIndex.value = newStep.id ?? ''
  }
}
const updateChangePlay = (value: boolean) => {
  autoPlay.value = value
  audioLocalStorage.value.autoPlay = value
}
const updateMute = (value: boolean) => {
  isMuted.value = value
  audioLocalStorage.value.isMuted = value
}
</script>

<template>
  <Layout
    hide-header
    footer="2024"
    class="bg-[#f7f7f7] min-h-[100vh] p-6 relative container"
  >
    <template v-if="notProd" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="flex justify-center absolute top-2 left-2">
      <AudioPlayer
        :audio-path="audioArray[audioIndex]"
        @change-auto-play="updateChangePlay"
        :auto-play="audioLocalStorage.autoPlay"
        :is-muted="audioLocalStorage.isMuted"
        @change-mute="updateMute"
      />
    </div>
    <Form
      id="myOtcBenefits2"
      class="!max-w-3xl"
      scrollBehavior="keepPos"
      :steps="[
        { id: 'start' },
        { id: 'medicareab' },
        { id: 'medicaid' },
        { id: 'call', completed: true },
      ]"
      product="medicare"
      @step-change="changeStep"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
