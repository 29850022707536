<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center !mt-0">
    What's your ZIP code?
  </div>

  <Field
    type="zip"
    name="zipcode"
    label="ZIP code"
    rules="required"
    class="!mt-0"
    :initialValue="query.zip"
  />

  <FormButtonNext />
  <FooterPhone2 />
</template>
