<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <div class="text-xl sm:text-3xl font-bold text-center">
    Have Medicare? Turning 65? Some Medicare members in
    {{ ip.lookup?.stateName || 'America' }} may qualify for
    <span class="text-red"> an allowance to use towards groceries </span>
    with some Medicare Advantage Plans.
  </div>

  <img
    src="../../assets/grocery-2024.jpeg"
    width="1100"
    height="576"
    class="w-[90%] sm:w-[400px] mx-auto"
  />

  <div class="text-3xl sm:text-4xl text-center">
    Looks like you may qualify for an allowance to spend on groceries!
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Act quickly: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
