<script setup lang="ts">
const { value: haveChronic } = useField('haveChronic')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Do you have any chronic conditions like Diabetes, Heart Disease, Cancer or
    Kidney Disease?
  </div>

  <div class="text-xl sm:text-2xl text-center">
    (optional) You may qualify for special plans.
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveChronic = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveChronic = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveChronic = null"
    >
      Skip
    </Button>
  </div>
</template>
