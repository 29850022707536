<script setup lang="ts">
const emit = defineEmits(['close'])

const props = defineProps<{
  open: boolean
  class?: string
}>()

const transitionTime = 1000

const isMobile = useIsMobile()

const contentClass = computed(() => {
  if (isMobile.value) {
    return [
      `global-modal-content duration-${transitionTime}`,
      props.open ? `bottom-0 h-[60%]` : `-bottom-[100%] h-0 overflow-hidden`,
    ]
  }
  return [
    'global-modal-content',
    'top-0 left-0 w-full flex align-center justify-center p-8',
  ]
})
</script>

<template>
  <HeadlessDialog
    :open="open"
    @close="emit('close')"
    :class="[`duration-${transitionTime}`]"
  >
    <HeadlessDialogOverlay
      class="fixed inset-0 bg-black bg-opacity-60 backdrop-blur"
    />
    <div :class="['absolute transform', contentClass]" @click.stop>
      <slot />
    </div>
  </HeadlessDialog>
</template>

<style scoped>
.global-modal-content {
  @apply transition-all ease-in-out w-full fixed left-0;
}
</style>
