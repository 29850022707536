<script setup lang="ts">
import { DateTime } from 'luxon'

const notProd = import.meta.env.MODE !== 'production'

useHead({
  title: `Click 2 lower premiums - ${brand.name}`,
})

const minGiveback = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
    ?.minGiveback
})

const maxGiveback = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
    ?.maxGiveback
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})
</script>

<template>
  <Layout
    v-if="notProd"
    header="simple3"
    :hideHeader="isMobile"
    footer="2024"
    class="container"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        Get
        <span class="text-red"
          >{{ minGiveback ? `$${minGiveback}` : '$0' }} up to
          {{ maxGiveback ? `$${maxGiveback}` : '$82' }}</span
        >/month back in {{ ip.lookup?.stateName || 'your state' }}
      </div>

      <div class="text-lg sm:text-2xl text-center">
        Get money back on your social security with the part b premium giveback
        from Medicare Advantage plans like UnitedHealthcare®
      </div>

      <div class="w-[90%] sm:w-[400px] mx-auto relative">
        <img src="../../assets/helpline-seniors-hands-up.png" class="w-full" />
        <StateMap
          v-if="ip.lookup?.stateCode"
          :state-code="ip.lookup.stateCode"
          class="absolute right-2 bottom-2 w-1/3 text-red"
        />
      </div>

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="click2LowerPremiums"
            class="!max-w-3xl py-6 px-4 sm:px-6"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'medicareab' },
              { id: 'new', if: !values?.haveMedicare },
              { id: 'medicaid' },
              { id: 'us' },
              { id: 'zipcode', if: !isPaidUser },
              { id: 'interested' },
              { id: 'loading' },
              { id: 'call' },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh"],
    "smid": "MULTI-PLAN_EHWEB062302_M",
    "robots": "noindex"
  }
}
</route>
