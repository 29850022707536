<script setup lang="ts">
import { BenefitView } from '~/modules/plan'

import dental from '~/assets/dental-fill.svg'
import vision from '~/assets/eye-fill.svg'
import card from '~/assets/card.svg'
import coin from '~/assets/coin-filled.svg'
import grocery from '~/assets/grocery.svg'
import home from '~/assets/home.svg'

const props = defineProps<{
  benefit: BenefitView
}>()

const image = computed(() => {
  switch (props.benefit.name) {
    case 'Dental':
      return dental
    case 'Vision':
      return vision
    case 'OTC':
      return card
    case 'Giveback':
      return coin
    case 'Allowance Card':
    case 'Flex Card':
      return card
    case 'Groceries':
      return grocery
    case 'Rent Assistance':
      return grocery
    default:
      return null
  }
})
const bgColor = computed(() => {
  switch (props.benefit.name) {
    case 'Dental':
    case 'Vision':
      return `#414BB2`
    case 'Giveback':
    case 'Allowance Card':
    case 'Flex Card':
      return `#652CB3`
    default:
      return `#D02606`
  }
})
</script>

<template>
  <div
    class="flex flex-col p-4 gap-4 rounded-lg text-white"
    :style="{
      backgroundColor: bgColor,
    }"
  >
    <div class="flex gap-4 items-center" v-if="image">
      <img :src="image" class="w-8 h-8" />
      <h3>{{ benefit.name }}</h3>
    </div>
    <span class="text-2xl font-semibold" v-html="benefit.description" />
  </div>
</template>
