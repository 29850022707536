<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Call ${brand.name} for Medicare quotes`,
})

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

// const maxOtc = computed(() => {
//   return states.find((state) => state.code === ip.lookup?.stateCode)?.maxOtc
// })
</script>

<template>
  <Layout
    header="simple3"
    :hideHeader="isMobile"
    footer="2024"
    class="container"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        Shop 2024 {{ ip.lookup?.stateName }} Medicare Advantage Plans
      </div>

      <div class="text-lg sm:text-2xl text-center">
        Find Medicare Advantage Plans with OTC, Grocery, or Flexible Spending
        Allowance programs near you.
      </div>

      <div class="w-[90%] sm:w-[400px] mx-auto relative">
        <img src="../../assets/helpline-seniors-hands-up.png" class="w-full" />
        <StateMap
          v-if="ip.lookup?.stateCode"
          :state-code="ip.lookup.stateCode"
          class="absolute right-2 bottom-2 w-1/3 text-red"
        />
      </div>

      <Form
        id="click4Otc"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'medicare' },
          { id: 'us' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call' },
        ]"
        product="medicare"
      />
    </div>
    <div class="space-y-6">
      <div class="text-2xl text-center font-semibold">
        Medicare Advantage and Part D plans and benefits offered by companies
        like:
      </div>

      <div class="text-lg text-center text-gray-500">
        Humana, UnitedHealthcare®, Anthem Blue Cross & Blue Shield, Aetna,
        Cigna, Wellcare, Kaiser Permanente
      </div>

      <div class="flex flex-col items-center gap-2">
        <div class="text-2xl text-center font-semibold">
          Tap Below to Speak with <br />
          Licensed Agent Now
        </div>
        <ClickToCall v-slot="{ handleClick }">
          <Button :to="phone.uri" @click="handleClick">
            <Icon i-heroicons-solid:phone class="w-8 h-8 animate-shake mr-4" />
            <span>
              {{ phone.formatted }} <br />
              TTY 711
            </span>
          </Button>
        </ClickToCall>
      </div>

      <div class="text-center text-gray-500 pb-8">
        Our licensed insurance agents may be busy, if you don't immediately get
        connected, please try again!
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "benefits": ["otc"],
    "smid": {
      "bh": "PLAN_EHWEB082329_M"
    },
    "robots": "noindex"
  }
}
</route>
