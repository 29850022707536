<script setup lang="ts">
import StateSelector from '~/components/StateSelector.vue'
import { PlanRowView } from '~/modules/plan'

useHead({
  title: `Shop 4 MA OTC - ${brand.name}`,
})

const state = ref(
  states.find(
    (state) => state.code === (query.statecode || ip.lookup?.stateCode || 'CA')
  )
)

const plans = ref<PlanRowView[]>([])
const filteredPlans = ref<PlanRowView[]>([])
const showAll = ref(false)
const showEditZipcodeModal = ref(false)
const loading = ref(false)

const loadPlans = async (code: string) => {
  try {
    loading.value = true
    const data = await getPlans({
      stateCode: code,
      amount: 100,
      starting_from: 0,
    })
    plans.value = data
      ?.filter((plan: PlanRowView) =>
        plan.benefits.some((benefit) => benefit.name === 'OTC')
      )
      .sort((planA, planB) => {
        const planAAmount =
          planA.benefits.find((benefit) => benefit.name === 'OTC')?.amount || 0
        const planBAmount =
          planB.benefits.find((benefit) => benefit.name === 'OTC')?.amount || 0

        return planBAmount - planAAmount
      })
      .slice(0, 20)

    filteredPlans.value = plans.value.slice(0, 5)
  } catch (e) {
    console.log('[DEBUG] There was some error when retrieving the plans', { e })
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  try {
    await until(() => ip.lookup).toBeTruthy()

    const zipCode = query.stateCode || ip.lookup?.stateCode

    if (zipCode) {
      loadPlans(zipCode)
    }
  } catch (e) {
    console.error('Could not load plans', { e })
  }
})

const otcBenefit = computed(() => {
  const otcBenefit = plans.value[0]?.benefits.find(
    (benefit) => benefit.name === 'OTC'
  )
  return (
    otcBenefit?.description ||
    `${USDollarFormatter(0).format(state.value?.minOtcLtd || 1500)}/per year`
  )
})

const isMobile = useIsMobile()

const submitStateCode = (value: string) => {
  loadPlans(value)
  showEditZipcodeModal.value = false
  state.value = states.find((state) => state.code === value)
}

const toggleShowPlans = () => {
  showAll.value = !showAll.value
  filteredPlans.value = showAll.value ? plans.value : plans.value.slice(0, 5)
}
</script>

<template>
  <Layout header="blue" footer="giveback" class="container">
    <template #banner v-if="!isMobile">
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <h2 class="space-y-2 text-lg text-red text-center font-bold">
        Compare 2025 Medicare Advantage Plans with OTC Allowance Benefits
      </h2>

      <div class="text-xl text-blue text-center font-semibold">
        Shop Medicare Advantage plans that may include a spending allowance for
        common plan-approved OTC items.
      </div>

      <img
        src="../../assets/shop-4-dsnp-otc-2024medicareab-allowance-card.jpg"
        width="1514"
        height="756"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Credit card illustration"
      />

      <div class="border-b border-gray-500 border-dashed"></div>

      <div class="space-y-6">
        <div class="text-2xl text-center font-semibold">
          Need an Allowance for common over-the-counter medications and
          supplies?
        </div>

        <div class="text-lg text-center text-gray-500">
          Over-the-counter (OTC) drugs are nonprescription medications; in other
          words, you don’t need a doctor’s prescription to purchase them. For
          some common ailments, such as a cold or headache, OTC drugs may be
          able to relieve your symptoms while saving you time and money.
        </div>
        <div class="text-2xl text-center font-semibold">
          How can Medicare Advantage help me save on these items?
        </div>
        <div class="text-lg text-center text-gray-500">
          Shop Medicare Advantage plans that may give you a monthly,quarterly or
          annual allowance towards plan-approved over-the-counter medications
          and supplies. When you select a Medicare Advantage plan with this
          benefit you’ll get a set amount, in many cases
          <span v-html="otcBenefit"></span> or more.
        </div>
        <div class="text-2xl flex flex-col items-center gap-2">
          <span class="text-base">
            Compare plans that feature an over-the-counter drug allowance in
          </span>
          <div class="flex justify-between items-center w-full font-semibold">
            {{ state?.name }}
            <Button
              class="underline text-blue"
              variant="transparent"
              size="sm"
              @click="showEditZipcodeModal = true"
            >
              Edit
            </Button>
          </div>
        </div>
        <div id="plan-wrapper" class="space-y-4" v-if="!loading">
          <div
            v-for="plan in filteredPlans"
            class="py-6 px-4 flex flex-col [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:px-6 sm:mx-0"
          >
            <div class="font-semibold">
              {{ plan.carrier }}
            </div>
            <div class="text-base">
              {{ plan.plan_name }}
              <span class="text-xs">[{{ plan.plan_id }}]</span>
            </div>
            <div class="font-semibold">
              {{ USDollarFormatter(0).format(plan.premium) }} Monthly plan
              premium
            </div>
            <ul class="list-disc flex flex-col pl-4">
              <li v-for="benefit of plan.benefits" class="text-black">
                {{ `${benefit.name} ${benefit.description && '-'}` }}
                <span v-html="benefit.description"></span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <Spinner class="mx-auto" />
        </div>
        <Button @click="toggleShowPlans" variant="darkBlue" wide size="sm">
          Show {{ showAll ? 'Less' : 'more' }}
        </Button>
        <div class="text-2xl text-center font-semibold">
          Compare plans from carriers like Humana and UnitedHealthcare®
        </div>
        <div class="text-center text-gray-500">
          <ClickToCall v-slot="{ handleClick }">
            <Button :to="phone.uri" @click="handleClick">
              <Icon
                i-heroicons-solid:phone
                class="w-8 h-8 animate-shake mr-4"
              />
              <span>
                {{ phone.formatted }} <br />
                TTY 711 24/7
              </span>
            </Button>
          </ClickToCall>
        </div>
        <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
          <Form
            id="shop4MAOtc"
            class="!max-w-3xl p-4"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'medicareab' },
              { id: 'medicaid' },
              { id: 'zipcode', if: !query.zipCode },
              { id: 'call', completed: true },
            ]"
            product="medicare"
          />
        </div>
        <div class="text-base text-gray-500 text-center">
          Just remember, not all plans offer all of these benefits. That’s why
          you should call and find the right plan for you.
        </div>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>

    <Modal :open="showEditZipcodeModal" @close="showEditZipcodeModal = false">
      <div
        class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-4"
      >
        Please select your State
        <StateSelector
          @submit-state-code="submitStateCode"
          button-label="Submit"
        />
      </div>
    </Modal>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "benefits": ["otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624184_M",
      "m10": "MULTI-PLAN_HLWEB1024282_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0624184_M",
      "m10": "MULTI-PLAN_HLWEB1024282_M"
    }
  }
}
</route>
