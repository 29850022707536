<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const needMoreInformation = ref<boolean>(
  !forms.shop4DsnpOtc2024es?.zipcode ||
    !forms.shop4DsnpOtc2024es?.medicare ||
    !forms.shop4DsnpOtc2024es?.medicaid
)

onMounted(async () => {
  const zipCode = forms.shop4DsnpOtc2024es?.zipcode

  try {
    plans.value = await getPlans({
      zipCode,
      carrier_groups: [],
      amount: 100,
      starting_from: 0,
    })
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center">
      <Spinner />
    </div>
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl text-red text-center font-medium">
      Hubo algunos errores al intentar encontrar planes para usted
    </div>
    <div class="text-xl text-center font-medium">
      Continuar con un <span class="whitespace-nowrap">Agente de Seguros Licenciado</span>
    </div>
    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Haga clic para llamar
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
  <template v-else>
    <div
      class="text-xl text-red text-center font-medium"
      v-if="needMoreInformation"
    >
      Se necesita más información
    </div>
    <div
      class="text-xl text-red text-center font-medium"
      v-else-if="plans?.length && plans?.length > 0"
    >
      {{ plans?.length }} planes disponibles para usted
    </div>
    <div class="text-xl text-red text-center font-medium" v-else>
      No se encontraron planes
    </div>

    <div class="text-xl text-center font-medium">
      Continuar con un <span class="whitespace-nowrap">Agente de Seguros Licenciado</span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Haga clic para llamar
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>