<script setup lang="ts">
import { DateTime } from 'luxon'

useHead({
  title: `Shop 4 DSNP OTC - ${brand.name}`,
})

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template #banner>
      <Banner> New to Medicare? Turning 65? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <h2 class="space-y-2 text-lg text-red text-center font-bold">
        Shop 2025 {{ ip.lookup?.stateName || '' }} Medicare Advantage Plans
      </h2>

      <img
        src="../../assets/shop-4-dsnp-otc-2024medicareab-allowance-card.jpg"
        width="819"
        height="455"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Credit card illustration"
      />

      <div class="text-xl text-blue text-center font-semibold">
        Find Medicare Advantage Plans with OTC or Flexible Spending Allowance
        benefits near you with carriers like Humana and UnitedHealthcare®
      </div>

      <div class="text-lg text-center">
        Not all plans offer these benefits. All of these benefits will not
        always be offered on a single Plan.
      </div>

      <div class="border-b border-gray-500 border-dashed"></div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="shop4DsnpOtcR2"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'us' },
            { id: 'medicaid' },
            { id: 'zipcode' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>

      <div class="text-2xl text-center font-semibold">
        Medicare Advantage and Part D plans and benefits offered by companies
        like Humana and UnitedHealthcare®
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "benefits": ["otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624187_M",
      "m10": "MULTI-PLAN_HLWEB0924254_M"
    }
  }
}
</route>
