<script setup lang="ts">
const { onSubmit } = inject('form') as any
</script>

<template>
  <FormProgress class="!mb-6" />

  <Checklist
    :items="[
      {
        text: 'Reviewing Your Answers...',
        icon: 'check',
      },
      {
        text: 'Matching You with the Best Options...',
        icon: 'check',
      },
      {
        text: 'Confirming Eligibility...',
        icon: 'check',
      },
    ]"
    :speed="15"
    @done="onSubmit()"
  />
</template>
