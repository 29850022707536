<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl text-center font-medium"> Search for plans </div>

  <div class="text-lg text-center font-medium"> Where do you live? </div>

  <Field
    type="zip"
    name="zipcode"
    rules="required"
    placeholder="Enter ZIP Code"
    class="text-center"
  />

  <FormButtonNext> Continue </FormButtonNext>
</template>
