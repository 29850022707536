<script setup lang="ts">
useHead({
  title: `See if you qualify - ${brand.name}`,
})

const showFooterPhone = ref(true)

const variant = ref<string>()

provide('variant', variant)

onMounted(() => {
  if (brand.id === 'med10') {
    variant.value = 'default'
    return
  }
  variant.value = 'default'
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="ping" class="container flex flex-col">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="flex-1 py-16">
      <Form
        id="go4Benefits2024"
        :steps="[
          { id: 'start' },
          { id: 'medicareab' },
          { id: 'medicaid' },
          { id: 'gender' },
          { id: 'zipcode' },
          { id: 'benefit' },
          { id: 'loading' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
      <FooterPhone2 v-if="showFooterPhone" @close="showFooterPhone = false" />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "boomer"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB072306_2024_M",
      "med10": "MULTI-PLAN_EHWEB072308_2024_M",
      "boomer": "MULTI-PLAN_EHWEB072309_2024_M"
    }
  }
}
</route>
