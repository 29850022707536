<script setup lang="ts">
const { value: liveInUs } = useField('liveInUs')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl text-center font-medium">
    Do you live in {{ ip.lookup?.stateName || 'America' }}?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="liveInUs = true"> Yes </Button>
    <Button wide size="sm" type="submit" @click="liveInUs = false"> No </Button>
  </div>
</template>
