<script setup lang="ts">
import AnimatedFormProgress from '~/components/animated-form/AnimatedFormProgress.vue'
import Medicare8td from '~/components/animated-form/medicare-medicaid-quiz-8td/Medicare8td.vue'
import Medicaid8td from '~/components/animated-form/medicare-medicaid-quiz-8td/Medicaid8td.vue'
import ZipCode8td from '~/components/animated-form/medicare-medicaid-quiz-8td/ZipCode8td.vue'
import Call8td from '~/components/animated-form/medicare-medicaid-quiz-8td/Call8td.vue'

const currentStep = ref(0)

const steps = computed(() => {
  const steps = [
    { component: Medicare8td, name: 'medicare' },
    { component: Medicaid8td, name: 'medicaid' },
  ]
  if (!query.zipCode) {
    steps.push({ component: ZipCode8td, name: 'zipcode' })
  }
  steps.push({ component: Call8td, name: 'call' })
  return steps
})

const changeStep = (stepNumber: number, oldStepNumber: number) => {
  currentStep.value = stepNumber
}
</script>

<template>
  <Layout header="blue" footer="2024">
    <div class="bg-gray-50 flex flex-col items-center justify-center">
      <AnimatedForm
        id="medicareMedicaidQuiz8td"
        scrollBehavior="keepPos"
        class="!w-full"
        product="medicare"
        @stepChange="changeStep"
        :show-progress="true"
        :steps="steps"
      >
        <template v-slot:progress>
          <AnimatedFormProgress
            :current-step="currentStep"
            :total="steps.length"
          />
        </template>
      </AnimatedForm>

      <div class="bg-gray-50 rounded-lg">
        <img
          src="../../assets/mock-rating.png"
          alt="mock image"
          class="max-w-lg rounded-lg"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback", "otc", "dental", "hearing", "vision"],
    "brands": ["m10", "bh"],
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0224150_M",
      "bh": "MULTI-PLAN_HLWEB0224144_M"
    },
    "xsmid": {
      "m10": "MULTI-PLAN_HLWEB0224150_M",
      "bh": "MULTI-PLAN_HLWEB0224144_M"
    },
    "robots": "noindex"
  }
}
</route>
