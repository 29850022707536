<script setup lang="ts">
const { value: liveInUs } = useField('liveInUs')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl sm:text-2xl text-center font-medium">
    Do you live in {{ ip.lookup?.stateName || 'America' }}?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="liveInUs = true"> Yes </Button>
    <Button wide size="sm" type="submit" @click="liveInUs = false"> No </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="liveInUs = null"
    >
      Skip
    </Button>
  </div>
</template>
