<script setup lang="ts">
const { onSubmit } = inject('form') as any
</script>

<template>
  <div class="text-xl text-center font-medium">
    Please hold on while I review your answers...
  </div>

  <Checklist
    :items="[
      {
        text: 'Lives in US',
        icon: forms.shop4DsnpOtcR2?.liveInUs ? 'check' : 'x',
      },
      {
        text: 'Has Medicare',
        icon: forms.shop4DsnpOtcR2?.haveMedicare ? 'check' : 'x',
      },
      {
        text: 'Has Medicaid',
        icon: forms.shop4DsnpOtcR2?.haveMedicaid ? 'check' : 'x',
      },
    ]"
    @done="onSubmit()"
  />
</template>
