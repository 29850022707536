<script setup lang="ts">
import type { Carrier } from '~/modules/carriers'

useHead({
  title: `New to Medicare? Moving? On Medicaid? - ${brand.name}`,
})

const variant = ref<string>('default')

provide('variant', variant)

const carriers = ref<Carrier[]>()
const stateCode = ref()
const showAllCarriers = ref(false)

const visibleCarriers = computed(() => {
  if (showAllCarriers.value) {
    return carriers.value
  }
  return carriers.value?.slice(0, 5)
})

const stateName = computed(() => {
  return states.find((state) => state.code === stateCode.value)?.name
})

const getStateCode = async () => {
  const queryState = query.state?.toUpperCase()

  if (queryState && states.map((state) => state.code).includes(queryState)) {
    stateCode.value = queryState
    return
  }

  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const ipLookupStateCode = ip.lookup?.stateCode

  if (ipLookupStateCode) {
    stateCode.value = ipLookupStateCode
    return
  }
  // TODO: fallback to ask state code modal
  carriers.value = []
}

onMounted(getStateCode)

watch(stateCode, async () => {
  carriers.value = await getCarriers(stateCode.value)
})

const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`/search-4-benefits-2024/search?zip=${values.zipcode}`)
})

analytics.page({
  name: 'Search 4 Carriers 2024',
  category: 'LP',
})
</script>

<template>
  <Layout header="ping" footer="2024" class="container flex flex-col">
    <template #banner>
      <Banner> New to Medicare? Moving? On Medicaid?</Banner>
    </template>

    <div class="flex flex-col py-12 gap-6">
      <div class="text-3xl sm:text-4xl text-center flex flex-col gap-4">
        <div>Discover the Most Popular</div>
        <div>Medicare Advantage carriers</div>
        <div>in {{ stateName }}</div>
      </div>

      <template v-if="!carriers">
        <div
          class="border border-gray-300 rounded-md py-6 flex flex-col items-center space-y-1"
        >
          <div class="text-darkBlue font-medium"> Loading… </div>
          <Spinner />
        </div>
      </template>
      <template v-else-if="carriers.length">
        <div class="space-y-2">
          <div
            v-for="carrier in visibleCarriers"
            :key="carrier.id"
            class="flex items-center border border-gray-300 rounded-md py-3 px-4"
          >
            <div class="text-lg font-medium flex-grow">
              {{ carrier.name }}
            </div>
            <div class="flex-grow-0">
              <span class="text-gray-600">
                {{ carrier.enrollments }} members
              </span>
              <span
                :class="[
                  carrier.change >= 0 ? 'text-green-600' : 'text-orange-600',
                  'inline-block ml-2',
                ]"
              >
                {{ (carrier.change >= 0 ? '+' : '') + carrier.change + '%' }}
              </span>
            </div>
          </div>
          <Button
            variant="red"
            wide
            v-if="!showAllCarriers && carriers.length > 5"
            @click="showAllCarriers = true"
          >
            Show More
          </Button>
          <Button
            variant="darkBlue"
            wide
            v-if="showAllCarriers && carriers.length > 5"
            @click="showAllCarriers = false"
          >
            Show Less
          </Button>
        </div>
      </template>

      <div class="text-3xl sm:text-4xl text-center">
        Shop for Plans in Your Area
      </div>
      <div class="flex w-full flex-col items-center">
        <form @submit="onSubmit" class="flex flex-col gap-6 w-96">
          <Field
            type="zip"
            name="zipcode"
            label="ZIP code"
            rules="required"
            class="flex-grow text-center"
            :initialValue="query.zip"
          />

          <Button wide type="submit" class="whitespace-nowrap"> Search </Button>
        </form>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "boomer", "med10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB082319_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_EHWEB082319_M"
    }
  }
}
</route>
