<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Continue below to compare plans in your area.
  </div>

  <div class="min-h-md">
    <MediaAlphaAd
      :placementId="
        query.utm_source?.toLowerCase() === 'ma'
          ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
          : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
      "
      :user="{}"
    />
  </div>
</template>
