<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const needMoreInformation = ref<boolean>(
  !forms.medicarePremiumReduction2096?.zipcode
)
onMounted(async () => {
  const zipCode = forms.medicarePremiumReduction2096?.zipcode
  try {
    plans.value = await getPlans({
      zipCode,
      carrier_groups: [],
      amount: 1000,
      starting_from: 0,
    })
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center">
      <Spinner />
    </div>
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl text-red text-center font-medium">
      There's some errors while trying to find plans for you
    </div>
    <div class="text-xl text-center font-medium">
      Continue w Licensed <span class="whitespace-nowrap">Insurance Agent</span>
    </div>
    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
  <template v-else>
    <div
      class="text-xl text-red text-center font-medium"
      v-if="needMoreInformation"
    >
      More Info Needed
    </div>
    <div
      class="text-xl text-red text-center font-medium"
      v-else-if="plans?.length && plans?.length > 0"
    >
      {{ plans?.length }} Plans Available to You
    </div>
    <div class="text-xl text-red text-center font-medium" v-else>
      No Plans Found
    </div>

    <div class="text-xl text-center font-medium">
      Continue w Licensed <span class="whitespace-nowrap">Insurance Agent</span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>
