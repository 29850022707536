<script setup lang="ts">
useHead({
  title: `See if you qualify - ${brand.name}`,
})

const showFooterPhone = ref(true)

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const route = useRoute()
const variant = ref<string>()

provide('variant', variant)

onMounted(() => {
  if (brand.id === 'med10') {
    variant.value = 'default'
    return
  }
  variant.value = 'default'
})
const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state) {
    return {
      code: state.code,
      name: state.name,
      medicaid: state.medicaid,
    }
  }
})
provide('state', state)
</script>

<template>
  <Layout class="bg-darkBlue flex flex-col min-h-[100vh]" footer="2024">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="container">
      <div class="flex-1 py-16 rounded-xl">
        <UseForm>
          <Form
            id="go4BenefitsBridgeDoor"
            class="bg-white rounded-xl shadow-xl py-16 p-8 md:p-16"
            :steps="[
              { id: 'start' },
              { id: 'options' },
              { id: 'medicaid' },
              { id: 'aditionalbenefits' },
              { id: 'review' },
              { id: 'call', completed: true },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "boomer"],
    "robots": "noindex"
  }
}
</route>
