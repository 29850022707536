<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)

const needMoreInformation = ref<boolean>(
  !forms.medicareMedicaidQuiz8td?.zipcode && !query.zipCode
)

const zipCode = computed(
  () => forms.medicareMedicaidQuiz8td?.zipcode ?? query.zipCode
)
const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  return {
    minOtcLtd: state?.minOtcLtd ?? 1500,
  }
})
const top5Otc = ref()

onMounted(async () => {
  try {
    plans.value = await getPlans({
      zipCode: zipCode.value,
      carrier_groups: [],
      amount: 1000,
      starting_from: 0,
    })

    await until(() => ip.lookup).toBeTruthy()

    if (ip.lookup?.postalCode) {
      const response = await getTop5Otcs({
        zip_code: zipCode.value,
        is_dsnp: forms.medicareMedicaidQuiz8td?.haveMedicaid,
      })

      top5Otc.value = USDollarFormatter(0).format(
        parseInt(`${response.min_otc}`, 10)
      )

      return
    }

    const stateTop5Otc =
      states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ??
      500
    top5Otc.value = USDollarFormatter(2).format(stateTop5Otc)
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <div class="container py-4 flex flex-col gap-4">
    <template v-if="loading && !error">
      <div class="flex items-center justify-center h-full">
        <Spinner size="lg" />
      </div>
    </template>
    <template v-else-if="!loading && error">
      <div class="text-xl text-red text-center font-medium">
        There's some errors while trying to find plans for you
      </div>
      <div class="text-xl text-center font-medium">
        Continue w Licensed
        <span class="whitespace-nowrap">Insurance Agent</span>
      </div>
      <ClickToCall :props="{ ctcLocation: 'last-step' }">
        <template #text>
          Click to Call
          <br />
          TTY 711, 24/7
        </template>
      </ClickToCall>
    </template>
    <template v-else>
      <div
        class="text-xl text-red text-center font-medium"
        v-if="needMoreInformation"
      >
        More Info Needed
      </div>
      <template v-else-if="plans?.length && plans?.length > 0">
        <h3 class="text-2xl text-center font-medium">
          Great! Because you have Medicare and are located in
          <span class="text-red">
            {{ zipCode }}
          </span>
          you may qualify for a Medicare Advantage plan with the following
          benefits.
        </h3>

        <ul class="flex flex-col bg-gray-100 gap-4 p-4 rounded-md">
          <li class="grid-items">
            <Icon i-heroicons-solid:check class="bg-blue-600 min-w-6 mt-1" />
            <div class="flex flex-col gap-1">
              <h4 class="text-lg font-semibold">Grocery Credit</h4>
              <p class="font-base"
                >At least
                <span class="text-green-600 font-semibold">{{ top5Otc }}</span
                >/yr pre-paid debit card for food and over-the-counter items</p
              >
            </div>
          </li>
          <li class="grid-items">
            <Icon i-heroicons-solid:check class="bg-blue-600 min-w-6 mt-1" />
            <div class="flex flex-col gap-1">
              <h4 class="text-lg font-semibold">General Living Support</h4>
              <p class="font-base">For rent, utilities, internet payments</p>
            </div>
          </li>
        </ul>
        <p class="font-semibold text-xl text-center">
          Call now to discuss your options
        </p>
        <ClickToCall :props="{ ctcLocation: 'last-step' }">
          <template #text>
            Click to Call
            <br />
            TTY 711, 24/7
          </template>
        </ClickToCall>

        <p class="text-base font-semibold text-center">
          Benefits described above may be available in your area
        </p>
      </template>
      <div class="text-xl text-red text-center font-medium" v-else>
        No Plans Found
      </div>
    </template>
  </div>
</template>

<style scoped>
.grid-items {
  @apply grid grid-cols-2 gap-4;
  grid-template-columns: 20px 1fr;
}
</style>
