<script setup lang="ts">
const { value: wantMoneyForGroceries } = useField('wantMoneyForGroceries')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Do you want to find a Medicare Advantage plan with
    <b>{{ state?.minOtcLtd }} or more</b> in Allowances to use towards
    groceries?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantMoneyForGroceries = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantMoneyForGroceries = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantMoneyForGroceries = null"
    >
      Skip
    </Button>
  </div>
</template>
