<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')
</script>

<template>
  <div
    class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-6"
  >
    <div class="text-xl sm:text-2xl text-red text-center font-medium">
      Easy Eligibility Quiz
    </div>

    <div class="text-xl sm:text-2xl text-center font-medium">
      Do you have Medicaid?
    </div>

    <div class="grid grid-cols-2 gap-4">
      <Button wide size="sm" type="submit" @click="haveMedicaid = true">
        Yes
      </Button>
      <Button wide size="sm" type="submit" @click="haveMedicaid = false">
        No
      </Button>
      <Button
        wide
        size="sm"
        type="submit"
        variant="gray"
        class="col-span-2"
        @click="haveMedicaid = null"
      >
        Skip
      </Button>
    </div>
  </div>
</template>
