<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
  label: string
  id: string
}>()

const emit = defineEmits(['update:modelValue'])

const isActive = ref(props.modelValue)

watch(
  () => props.modelValue,
  (newValue) => {
    isActive.value = newValue
  }
)

const handleChange = () => {
  isActive.value = !isActive.value
  emit('update:modelValue', isActive.value)
}
</script>

<template>
  <label class="relative flex gap-4 items-center" :for="id">
    <input
      type="checkbox"
      v-model="isActive"
      @change="handleChange"
      :id="id"
      class="hidden"
    />
    <div
      :class="{ 'bg-blue-400': isActive, 'bg-gray-200': !isActive }"
      class="w-12 h-7 rounded-full p-1 cursor-pointer block"
    >
      <span
        :class="{ 'translate-x-5': isActive }"
        class="bg-white w-5 h-5 rounded-full transition-transform duration-300 ease-in-out block"
      ></span>
    </div>
    {{ label }}
  </label>
</template>
