<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center text-red font-bold">
    Good News!
  </div>

  <div class="text-xl sm:text-2xl text-center">
    We found plans in your area with
    <span class="text-red"> additional benefits! </span>
  </div>

  <div class="text-2xl sm:text-3xl text-center">
    Call the {{ brand.name }} now to review them with a licensed insurance agent
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-center text-gray-500"> Available M-F 9-7 EST </div>

  <div class="flex items-center justify-center space-x-4">
    <div class="relative flex w-4 h-4">
      <div
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
      >
      </div>
      <div class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></div>
    </div>
    <div class="text-lg sm:text-xl font-medium">
      Licensed sales agents <br />
      are standing by now
    </div>
  </div>
</template>
