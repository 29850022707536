<script setup lang="ts">
import { CountyResponse } from '~/modules/county'
import { PlanRowView } from '~/modules/plan'

const { value: showZip } = useField('showZip')

const plans = ref<PlanRowView[]>([])
const loading = ref(false)
const error = ref(false)

const stateCode = ref()
const plansString = ref<string>('')
const county = ref<CountyResponse>({
  name: 'your area',
  fips: '',
  state: stateCode.value ?? '',
})

provide('stateCode', stateCode)
provide('county', county)

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 100
  error.value = false
  loading.value = true
  try {
    await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

    const zipCode = ip.lookup?.postalCode
    stateCode.value = ip.lookup?.stateCode ?? undefined
    const benefits = ['otc_summary']

    if (zipCode) {
      plans.value = await getPlans({
        starting_from: startingFrom,
        amount: amount,
        zipCode: zipCode,
        benefits,
      })
      const countyResponse = await getCounty(zipCode)
      if (countyResponse?.name) {
        county.value = countyResponse
      }
    } else if (stateCode) {
      plans.value = await getPlans({
        starting_from: startingFrom,
        amount: amount,
        stateCode: stateCode.value,
        benefits,
      })
    }

    if (plans.value.length > 0) {
      const uniqueCarriers = Array.from(
        new Set(plans.value.map((plan) => plan.carrier))
      )
        .slice(0, 3)
        .map((carrierName) => {
          switch (carrierName) {
            case 'United':
              return 'UnitedHealthcare®'
            case 'Blues':
              return `Anthem / Blue Cross`
            default:
              return carrierName
          }
        })
      plansString.value = uniqueCarriers.join(', ')
    }
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
}
onMounted(loadData)
</script>

<template>
  <FormProgressSteps />
  <div v-if="loading" class="p-8">
    <Spinner class="mx-auto" />
  </div>
  <div v-if="!loading && error" class="text-3xl sm:text-4xl text-center">
    We have some issues with our servers. Please try again later.
    <Button @click="loadData">Try Again</Button>
  </div>
  <div
    v-if="plansString.length > 0"
    class="text-xl leading-6.5 sm:text-3xl font-bold text-center"
  >
    Did you know that <span class="text-red">{{ plansString }}</span> offer
    grocery or over the counter allowances in {{ county.name }}?
  </div>
  <div class="text-lg sm:text-2xl text-center">
    The Grocery Allowance benefit is extremely popular, but it may not be
    available in your area. Do you want to check your zipcode for this benefit?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      @click="showZip = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="showZip = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="showZip = null"
    >
      Skip
    </Button>
  </div>
</template>
