<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div class="text-xl sm:text-2xl text-center font-semibold">
    Do you have Medicare <span class="whitespace-nowrap">Parts A & B?</span>
  </div>

  <div class="grid grid-cols-3 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      class="col-span-2 !bg-blue-100 !text-gray-900 ring ring-1 ring-blue-300 !text-xl !leading-6"
      @click="haveMedicare = true"
    >
      Yes (👍 tap here)
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      @click="haveMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
