<script setup lang="ts">
const { value: haveMedicaid } = useField('haveMedicaid')
</script>

<template>
  <div class="text-xl sm:text-2xl text-center font-semibold">
    Do you have Medicaid?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      class="!bg-blue-100 !text-gray-900 ring ring-1 ring-blue-300 !text-xl !leading-6"
      @click="haveMedicaid = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      class="!bg-blue-100 !text-gray-900 ring ring-1 ring-blue-300 !text-xl !leading-6"
      @click="haveMedicaid = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Skip
    </Button>
  </div>
</template>
