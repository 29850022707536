<script setup lang="ts">
import allowanceCard from '../../assets/allowance-card-blank.png'
import allowanceCard3 from '../../assets/allowance-card-3.png'
import groceryHaul from '../../assets/grocery-haul-gif.gif'

useHead({
  title: `Beneficios Adicionales OTC 2024 - ${brand.name}`,
})

const state = computed(() => {
  const state = states.find(
    (state) => state.code === 'CA'
    // ip.lookup?.stateCode
  )
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})

provide('state', state)
enterExperiment('additionalBenefitsOtc2024Headline')
enterExperiment('additionalBenefitsOtc2024Image')

const image = computed(() => {
  const image =
    getCurentValidExperiment(exp.value?.additionalBenefitsOtc2024Image) ??
    'allowanceCard'
  switch (image) {
    default:
      return allowanceCard
    case 'allowanceCard3':
      return allowanceCard3
    case 'groceryHaul':
      return groceryHaul
  }
})

const headline = computed(() => {
  const headline =
    getCurentValidExperiment(exp.value?.additionalBenefitsOtc2024Headline) ??
    'someMedicareBeneficiaries'
  switch (headline) {
    default:
      return `Algunos beneficiarios de Medicare de ${
        state.value?.name ?? 'tu estado'
      } podrían ser elegibles para una asignación de OTC de ${
        state.value?.minOtcLtd ?? '$500'
      } /año con planes de Medicare Advantage como Humana, UnitedHealthcare®, y Aetna`
    case 'stateMedicareAdvantagePlus':
      return `Planes de Medicare Advantage de ${
        state.value?.name ?? 'tu estado'
      } para 2024`
    case 'clickfor2024Medicare':
      return `Haga clic para ver los Planes de Medicare Advantage de ${
        state.value?.name ?? 'tu estado'
      } para 2024`
    case 'youMayQualify':
      return `Puede Calificar para ${
        state.value?.minOtcLtd ?? '$500'
      }/por año o más en Asignación para Comestibles`
  }
})
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> ¿Nuevo en Medicare? ¿En Medicaid? ¿Se está mudando? </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div
        class="headline text-2xl text-center text-red font-medium font-dm"
        v-html="headline"
      >
      </div>
      <img
        :src="image"
        width="1024"
        height="1024"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Señora mayor feliz en un sillón dental"
      />
      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="otc2024es"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicaid' },
            { id: 'otcallowance' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0524174_M",
      "bh": "MULTI-PLAN_HLWEB1023108_M"
    }
  }
}
</route>
