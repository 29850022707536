<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
const { value: zipcode } = useField('zipcode')
onMounted(() => {
  if (query.zipCode) {
    zipcode.value = query.zipCode
  }
})
</script>

<template>
  <div class="text-xl text-center font-medium"> Do you have Medicare? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="haveMedicare = true">
      Yes
    </Button>
    <Button wide size="sm" type="submit" @click="haveMedicare = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
