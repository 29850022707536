<script setup lang="ts">
const { onSubmit } = inject('form') as any
</script>

<template>
  <FormProgress class="!mb-6" />

  <div>
    <Spinner class="mx-auto" />

    <Checklist
      :items="[
        {
          text: 'Reviewing Your Answers...',
          icon: 'check',
        },
        {
          text: 'Matching You with the Best Options...',
          icon: 'check',
        },
        {
          text: 'Confirming Eligibility...',
          icon: 'check',
        },
      ]"
      :speed="15"
      @done="onSubmit()"
    />
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
