<script setup lang="ts">
import variant1 from '../../assets/buyback-couple-swimming.png'
import variant2 from '../../assets/bingo.jpg'
import variant3 from '../../assets/buyback-older-couple.jpg'
import variant4 from '../../assets/giveback-page-hero.jpg'
import variant5 from '../../assets/buyback-2024.jpeg'

useHead({
  title: `Medicare Premium Reduction 2096 - ${brand.name}`,
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

enterExperiment('medicarePremiumReduction2096Headline')
enterExperiment('medicarePremiumReduction2096Text')
enterExperiment('medicarePremiumReduction2096Image')

const imageObj = [
  null,
  {
    image: variant1,
    alt: 'Senior couple swimming',
  },
  {
    image: variant2,
    alt: 'Senior ladies enjoying their time in a bingo',
  },
  {
    image: variant3,
    alt: 'An elderly couple smiling joyfully at the camera in an airport terminal, with the man holding up boarding passes.',
  },
  {
    image: variant4,
    alt: 'An elderly lady joyfully holding a giveback check.',
  },
  {
    image: variant5,
    alt: 'An elderly couple dancing in the beach with naked feets enjoying the sunset.',
  },
  ,
]
let key = 1
if (exp.value?.medicarePremiumReduction2096Image) {
  for (const k of Object.keys(exp.value.medicarePremiumReduction2096Image)) {
    if (
      (exp.value.medicarePremiumReduction2096Image as any)[k as any] === true
    ) {
      let value = parseInt(k.replace('variant', ''), 10)
      key = value
    }
  }
}

const image = ref(imageObj[1])
image.value = imageObj[key ?? 1]
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="space-y-2">
        <div class="text-xl text-red text-center font-medium">
          ATTENTION PEOPLE ON MEDICARE!
        </div>
        <div
          class="text-xl text-center font-medium"
          v-if="exp?.medicarePremiumReduction2096Headline?.original"
        >
          SHOP PLANS WITH PART B PREMIUM REDUCTION BENEFIT
        </div>
      </div>

      <img
        :src="image?.image"
        width="1514"
        height="756"
        class="w-[90%] sm:w-[400px] mx-auto"
        :alt="image?.alt"
      />
      <template v-if="exp?.medicarePremiumReduction2096Text?.original">
        <div class="text-xl text-red text-center font-medium">
          See If You Could Lower Your $2096/yr Medicare Part B Premium By
          {{ USDollarFormatter(0).format(minOtcLtd) }} or more with the
          “Giveback” benefit.
        </div>

        <div class="text-xl text-blue text-center">
          Shop plans from carriers like <br />
          <span class="font-medium"> HUMANA, UNITEDHEALTHCARE® </span>
        </div>
      </template>
      <template v-else>
        <div class="text-xl text-red text-center font-medium">
          You May Be Eligible for Part B Giveback with some Medicare Advantage
          Carriers like
        </div>
        <div class="text-xl text-center font-medium">
          <span class="font-medium"> HUMANA, UNITEDHEALTHCARE® </span>
        </div>
      </template>
      <div class="border-b border-gray-500 border-dashed"></div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="medicarePremiumReduction2096"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'zipcode' },
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh"],
    "smid": "MULTI-PLAN_HLWEB0124133_M",
    "xsmid": "MULTI-PLAN_HLWEB0124133_M",
    "robots": "noindex"
  }
}
</route>
