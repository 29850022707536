<script setup lang="ts">
useHead({
  title: `Medicare - ${brand.name}`,
})

analytics.page({
  name: 'Advertorial medicare v1',
  category: 'LP',
  product: 'medicare',
})

const to =
  query.flow === 'helpline'
    ? '/helpline'
    : query.flow === 'healthy-foods'
    ? '/healthy-foods'
    : query.flow === 'additional-benefits-buyback-2024'
    ? '/additional-benefits-buyback-2024'
    : '/additional-benefits-grocery-2024'

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout>
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicare and Medicaid? </Banner>
    </template>
    <div class="container max-w-2xl py-8 space-y-6 text-lg">
      <div class="text-center">
        New to Medicare, Turning 65, Losing coverage or Moving?
      </div>

      <h1 class="text-2xl font-bold text-center">
        Does Your Current Medicare Advantage Plan Include These Benefits? (See
        What You Qualify For)
      </h1>

      <img src="../../assets/medicare-v1.png" class="w-full" />

      <div>
        Finding a Medicare Advantage Plan that you want shouldn't be confusing.
        There are so many choices, that it can be overwhelming to make sure you
        pick a plan that fits your needs.
      </div>

      <div>
        Luckily, {{ brand.name }} is here to help! {{ brand.name }} is
        <b>helping you take control of your healthcare journey!</b>
      </div>

      <div>
        Depending on where you live, if you are new to Medicare, losing
        coverage, or moving you may qualify for a Medicare Advantage Plan from
        well-known carriers like Humana and UnitedHealthcare® with a variety of
        benefits that could include:
      </div>

      <ul class="list-disc list-inside">
        <li> Healthy Food Allowance </li>
        <li> Money back on your social security </li>
        <li> Additional Vision, Hearing, or Dental Coverage </li>
        <li> Monthly Gym Allowance </li>
        <li> Prescription Drugs Coverage </li>
        <li> & Possibly More!! </li>
      </ul>

      <div class="bg-yellow-100 border border-yellow-500 p-4 rounded-md">
        It's free to check what benefits you can qualify for with a Medicare
        Advantage plan by speaking with a Licensed Insurance Agent at
        <ClickToCall v-slot="{ handleClick }">
          <Link
            class="!text-blue-700 font-medium whitespace-nowrap"
            :to="phone.uri"
            @click="handleClick"
          >
          {{ phone.formatted }}
          </Link>
        </ClickToCall>
        TTY 711, 24/7. There is no obligation to enroll.
      </div>

      <div>
        Even if you've reviewed plans recently, it's common to sometimes
        overlook benefits. You might have a sense that you're on the right track
        with your plan, but have you considered if it truly
        <b>aligns with your specific needs?</b>
      </div>

      <div>
        <b>What's Next?</b>
      </div>

      <div>
        Step 1) Select your age range. <br />
        Step 2) After you've entered your zip code and completed the 2-minute
        questionnaire, you'll be connected with a licensed agent.
      </div>

      <div class="text-xl font-bold text-center"> Select Your Age: </div>

      <div class="grid grid-cols-2 gap-2">
        <Button :to="to" variant="blue"> 16-25 </Button>
        <Button :to="to" variant="blue"> 26-35 </Button>
        <Button :to="to" variant="blue"> 36-45 </Button>
        <Button :to="to" variant="blue"> 46-55 </Button>
        <Button :to="to" variant="blue"> 56-65 </Button>
        <Button :to="to" variant="blue"> 66+ </Button>
        <Button :to="to" variant="blue" class="col-span-2">
          <span>
            Check Eligibility <span class="whitespace-nowrap">(skip)</span>
          </span>
        </Button>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10","bh"],
    "robots": "noindex"
  }
}
</route>